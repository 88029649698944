import {  getEunomiaToken } from '../lib';
import {
  EUNOMIA_HEADER_TOKEN,
  EUNOMIA_TOKEN,
  HEADER_SN_TOKEN,
  HEADER_SN_URL,
  HEADER_ACCOUNT_URL, EUNOMIA_PENDING,
} from '../constants';
import { accessToken, domainName, myUrl } from '../../initial_state';

export function onEunomiaToken(data) {
  return { type: EUNOMIA_TOKEN, payload: data };
}


export function setEunomiaPending(url) {
  return { type: EUNOMIA_PENDING, url };
}


export const getEunomiaHeaders = () => {
  return new Promise(((resolve, reject) => {
    const headers = {};
    getEunomiaToken().then((data) => {
      if (data) {
        const { token } = data;
        headers[EUNOMIA_HEADER_TOKEN] = token;
        headers[HEADER_SN_TOKEN] = accessToken;
        headers[HEADER_SN_URL] = `https://${domainName}`;
        headers[HEADER_ACCOUNT_URL] = myUrl;
        resolve(headers);
      }
    }).catch((reason) => {
      reject(reason);
    });
  }));
};


export const eunomiaTokenFromMastodonToken = () => dispatch => {
  getEunomiaToken().then((data) => {
    if (data) {
      const { token, created } = data;
      dispatch(onEunomiaToken({ token, created }));
    }
  }).catch(()=>{});
};
