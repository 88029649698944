import {
  EUNOMIA_VOTING_INIT,
  EUNOMIA_STATS_IMPORT,
  CURRENT_USER_VOTED,
  NEW_VOTING,
} from '../constants';
import { accessToken, myId } from '../../initial_state';
import votingOld from '../voting_old/lib';
import votingNew from '../voting_new/lib';
import { getEunomiaToken, plainDomain } from '../lib';
import { getEunomiaHeaders, setEunomiaPending } from './common';
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { importEunomiaPost, importEunomiaPosts } from './posts';

const voting = NEW_VOTING ? votingNew : votingOld;

export const setVotingReinit = () => {
  if (window.sessionStorage.getItem(EUNOMIA_VOTING_INIT)) {
    window.sessionStorage.removeItem(EUNOMIA_VOTING_INIT);
  }
  voting.clear([]).then(()=>{}).catch(()=>{});
};


const getMyStats = (dispatch) => {
  const url = '/eunomia/api/me';
  getEunomiaHeaders().then((headers) => {
    axios.get(url, { headers }).then((response) => {
      if (response.data && response.data.stats) {
        dispatch({ type: EUNOMIA_STATS_IMPORT, payload: response.data.stats });
      }
    }).catch(() =>dispatch(setEunomiaPending(url)));
  }).catch(() => dispatch(setEunomiaPending(url)));
};

export const getUserPostVotes = (userId, post) => {
  return new Promise((resolve) => {
    post.voted = false;
    post.trusted = null;
    if (post.source_id && post.source_url) {
      const postId = `${post.source_id}@${plainDomain(post.source_url)}`;
      voting.hasVoted(userId, postId)
        .then(({ message }) => {
          if (message) {
            const { voted, ballot } = message;
            if (voted !== undefined && ballot !== undefined) {
              const checkTrusted = ballot.votes !== undefined && ballot.votes.trust !== undefined && ballot.votes.no_trust !== undefined;
              // eslint-disable-next-line no-console
              // console.log(ballot);
              post.voted = checkTrusted ?? (voted && ballot.votes.trust !== ballot.votes.no_trust);
              post.trusted = checkTrusted ? ballot.votes.trust > ballot.votes.no_trust : null;
            }
          }
          resolve(post);
        }).catch(() => {
          resolve(post);
        });
    } else {
      resolve(post);
    }
  });
};

export const onSomeoneVoted = (post) => (dispatch) => {
  getEunomiaToken().then((data) => {
    if (data) {
      getMyStats(dispatch);
      if (post.source_id && post.source_url && post.votes) {
        getUserPostVotes(myId, post).then((_post) => {
          dispatch(importEunomiaPost(_post));
        }).catch(console.warn);
      }
    }
  }).catch();
};


export const onVPostResults = (posts) => (dispatch) => {
  try {
    if (accessToken !== null) {
      const postsToImport = [];
      posts.forEach((post, index) => {
        if (post.source_id && post.source_url) {
          getUserPostVotes(myId, post).then((_post) => {
            postsToImport.push(_post);
            if (index === posts.length - 1) {
              dispatch(importEunomiaPosts(postsToImport));
            }
          }).catch(() => {
            if (index === posts.length - 1) {
              dispatch(importEunomiaPosts(postsToImport));
            }
          });
        }
      });
    }
  } catch {}
};

const notify_voted = (sourceId, cb) => {
  const url = `/eunomia/api/voted/${sourceId}`;
  getEunomiaHeaders().then((headers) => {
  // eslint-disable-next-line promise/catch-or-return
    axios.get(url, { headers }).then(()=>{}).catch(()=>{}).finally(cb());
  }).catch(console.warn);
};

export const onEunomiaVote = (post, status, trust) => (dispatch) => {
  if (accessToken !== null) {
    const sourceId = post.get('source_id', null);
    const sourceUrl = post.get('source_url', null);
    if (sourceId && sourceUrl) {
      const postId = `${sourceId}@${plainDomain(sourceUrl)}`;
      getEunomiaToken().then((data) => {
        if (data) {
          const { token } = data;
          voting.getBallot(token, postId, myId).then((response) => {
            let { ballot } = response.message;
            if (trust) {
              ballot.votes.trust = 1;
              ballot.votes.no_trust = 0;
            } else {
              ballot.votes.trust = 0;
              ballot.votes.no_trust = 1;
            }
            voting.vote(token, myId, { ballot }, false).then(() => {
              notify_voted(sourceId, () => dispatch({ type: CURRENT_USER_VOTED }));
            }).catch((e1) => {
              console.warn(e1);
              getUserPostVotes(myId, post).then((_post) => {
                dispatch(importEunomiaPost(_post));
              }).catch(()=>{});
              // notify_voted(postId, () => {});
            });
          }).catch((e2) => {
            console.warn(e2);
            getUserPostVotes(myId, post).then((_post) => {
              dispatch(importEunomiaPost(_post));
            }).catch(()=>{});
            // notify_voted(postId, () => {});
          });
        }
      }).catch((e3) => {
        console.warn(e3);
      });
    }
  }
};
