'use strict';

const axios = require('axios');
const crypto = require('./cryptography.js');
const level = require('level');

let services = (function() {

  // node information
  const NODE_PORT = '443';
  const NODE_INFO_PATH = '/storage/node/info';

  // discovery service information
  const DISCOVERY_PATH = '/storage/discovery';

  // discovery service url
  let discoveryUrl;

  // create or open database files
  const storage = level('./storage', { 'valueEncoding': 'json' });

  // dictionary of methods exported by this module
  let calls = {};

  //------------------------------------------------------------------------------------------
  // NODE INFORMATION
  //------------------------------------------------------------------------------------------

  /**
	 * Extracts EUNOMIA Node general information from the node
	 * information service, including Voting Server ID.
	 *
	 * @param endpoint - Endpoint of the EUNOMIA Node
	 *
	 * @returns {Promise<Object>} Object with Node Information
	 */
  calls.getNodeInfo = function(endpoint) {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve, reject) => {
      let url;
      try {
        // parse url and set parameters
        url = new URL(endpoint);
        url.port = NODE_PORT;
        url.pathname = NODE_INFO_PATH;
      } catch (e) {
        // returns to stop execution
        return reject(calls.setError(e.message, calls.service.CLIENT));
      }

      // make request
      axios.get(url.toString()).then(response => {
        // check if voting service id exists
        if ('votingServiceId' in response.data)
          resolve(response.data);

        // reject if it does not exist
        reject(calls.setError('EUNOMIA Node Information is invalid', calls.service.DISCOVERY));
      }).catch(error => {
        reject(calls.setError(error, calls.service.DISCOVERY));
      });
    });
  };

  //------------------------------------------------------------------------------------------
  // DISCOVERY SERVICES
  //------------------------------------------------------------------------------------------

  let discovery = {};

  /**
	 * Defines an URL to access the Discovery Service.
	 *
	 * @param {String} endpoint - Endpoint of the Discovery Service
	 *
	 * @return {Promise} Empty or Error
	 */
  discovery.setUrl = function(endpoint) {
    return new Promise((resolve, reject) => {
      try {
        // parse endpoint and set url
        let url = new URL(endpoint);
        url.port = NODE_PORT;
        url.pathname = DISCOVERY_PATH;
        discoveryUrl = url.toString();

        // resolve promise
        resolve();
      } catch (e) {
        reject(calls.setError(e.message, calls.service.CLIENT));
      }
    });
  };

  /**
	 * Uses discovery service to gather info on voting servers (this function is
	 * only called inside the services file).
	 *
	 * @param {String} token - EUNOMIA token for the user
	 * @param {String} id - ID of Voting Server OR null to return every Voting Server
	 *
	 * @return {Promise<Array>} List of Voting Servers with format
	 * [{manager: <String>, tallier: <String>, anonymizer: <String>, live: <boolean>, id: <String>}, ...]
	**/
  function searchServices(token, id) {
    if (discoveryUrl === undefined)
      return Promise.reject(calls.setError('No endpoint available for discovery service', calls.service.CLIENT));

    // build url
    let url = discoveryUrl + `?access_token=${token}&property=type&comp=eq&value=voting`;

    // update url (if necessary)
    if (id !== null) url += `&property=id&comp=eq&value=${id}`;

    // make request asynchronously
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        // get list of voting servers
        let servers = response.data.data.entities;

        // check response
        if (servers !== undefined && servers.length < 1)
          reject(calls.setError('No voting servers available' + (id !== null ? ` with ID ${id}` : ''), calls.service.CLIENT));

        // build services array
        let res = [];
        servers.forEach(server => {
          // create server and add to array
          let s = server.properties;
          s.id = server.id;
          res.push(s);
        });

        // return services
        resolve(res);
      }).catch(error => {
        // reject promise with error
        reject(calls.setError(error, calls.service.DISCOVERY));
      });
    });
  }

  /**
	 * Calls the discovery service to get credentials on the voting server
	 * with the specified ID, whether it is running or not
	 *
	 * @param {String} token - Authentication token of EUNOMIA user
	 * @param {String} id - ID of voting service
	 *
	 * @return {Promise<Object>} Dictionary with voting service cryptographic information
	**/
  discovery.getVotingServer = function(token, id) {
    // get voting server by id
    return new Promise((resolve, reject) => {
      searchServices(token, id)
        .then(servers => resolve(servers[0]))
        .catch(e => reject(e));
    });
  };

  // append discovery methods to calls
  calls.discovery = discovery;

  //------------------------------------------------------------------------------------------
  // USERS
  //------------------------------------------------------------------------------------------

  let users = {};

  calls.register = function(token, userId, password) {
    // generate key
    // encrypt key with password
    return Promise.resolve({ token, userId, password });
  };


  calls.login = function(token, userId, password) {
    // get encrypted key
    // return key (user object)
    return Promise.resolve({ token, userId, password });
  };

  calls.users = users;

  //------------------------------------------------------------------------------------------
  // ANONYMITY/MIXING
  //------------------------------------------------------------------------------------------

  /**
	 * Calls the discovery service to get services and builds a mix
	 * network of anonymizers with corresponding IDs and keys.
	 *
	 * @param {String} token - EUNOMIA token for the user
	 * @param {number} size - Size of mix network (number of anonymizers)
	 * @param {String} firstId - Identifier of the first voting server of the mix
	 *
	 * @return {Promise<Array>} List of <size> anonymizers [{id: <id>, key: <key>}, ...]
	 * or error
	**/
  function buildMix(token, size, firstId) {
    // get list of voting services
    return new Promise((resolve, reject) => {
      searchServices(token, null).then(servers => {
        // define first anonymizer
        let firstAnonymizer = {
          'id': firstId,
        };

        // filter anonymizers (offline, first)
        servers = servers.filter(s => {
          if (s.id === firstId) firstAnonymizer.certificate = s.anonymizer;
          return s.live && s.id !== firstId;
        });

        // check size
        if (servers.length < size - 1)
          reject(services.setError('Not enough anonymizers available to build mix network', services.service.CLIENT));

        // declare mix network
        let anonymizers = [];

        // add anonymizers to array
        for (let i = 0; i < size - 1; i++) {
          // random index
          let index = Math.floor(Math.random() * servers.length);
          let anon = {
            'id': servers[index].id,
            'certificate': servers[index].anonymizer,
          };

          // append to anonymizers and remove from servers
          anonymizers.push(anon);
          servers.splice(index, 1);
        }

        // add first anonymizer
        anonymizers.push(firstAnonymizer);

        // return array of anonymizers
        resolve(anonymizers);
      }).catch(e => reject(e));
    });
  }

  /**
	 * Builds an anonymization circuit and encrypts and prepares the data
	 * to go through the mix network.
	 *
	 * @param {String} accessToken - EUNOMIA token used to get anonymizer information
	 * @param {number} mixSize - Number of mix servers that will make up the mix network
	 * @param {String} serverId - Identifier of the voting server the user is in contact with
	 * @param {Object} body - Message being encrypted
	 *
	 * @returns {Promise<Object>} Data encrypted and prepared
	 */
  calls.anonymize = function(accessToken, mixSize, serverId, body) {
    // build mix and encrypt data
    return buildMix(accessToken, mixSize, serverId).then(anonymizers => {
      // encrypt (onion)
      anonymizers.forEach((anon, index) => {
        // handle keys
        let rsaKey = crypto.rsa.keyFromCertificate(anon.certificate);
        let aesKey = crypto.aes.generateKey();

        // encrypt body with symmetric key
        let encryptedData = crypto.aes.encrypt(body, aesKey);

        // encrypt info with anonymizer key
        let info = {
          'key': aesKey,
          'iv': encryptedData.iv,
          'next': (anonymizers[index - 1] !== undefined) ? anonymizers[index - 1].id : null,
        };
        let infoEncrypted = crypto.rsa.encrypt(info, rsaKey);

        // update body
        body = {
          'info': infoEncrypted,
          'data': encryptedData.data,
        };

        // eslint-disable-next-line no-console
        console.log(body);
      });

      // return encrypted data
      return body;
    });
  };

  //------------------------------------------------------------------------------------------
  // STORAGE (LEVELDB)
  //------------------------------------------------------------------------------------------

  // methods exported by this module related to local storage
  let db = {};

  /**
	 * Gets data from the LevelDB instance.
	 *
	 * @param {String} key - Storage key matching the desired object
	 *
	 * @return {Promise<Object>} Resolves object extracted from the database or null if
	 * it does not exist and rejects if there is an error different than NotFound
	**/
  db.get = function(key) {
    return new Promise((resolve, reject) => {
      storage.get(key, function (err, value) {
        if (err)
          if (err.type === 'NotFoundError')
            resolve(null);
          else
            reject(calls.setError('Local database search failed', calls.service.CLIENT));
        resolve(value);
      });
    });
  };

  /**
	 * Saves data to the current LevelDB instance.
	 *
	 * @param {String} key - Key to identify the object in the database
	 * @param {Object} object - Data to be saved associated with the key
	 *
	 * @return {Promise<Object>} State of the put operation
	 **/
  db.save = function(key, object) {
    return new Promise((resolve, reject) => {
      storage.put(key, object, function(err) {
        if (err)
          reject(calls.setError('Local database save operation failed', calls.service.CLIENT));
        resolve('Object successfully saved');
      });
    });
  };

  /**
	 * Deletes a number of keys and corresponding objects from the LevelDB
	 * local database, or clears the local storage if no arguments are
	 * passed to the function.
	 *
	 * @param {Array<String>} keys - Variable number of keys to be deleted from local storage
	 *
	 * @return {Promise<Object>} State of the delete/clear operation
	 */
  db.clear = function(keys) {
    return new Promise((resolve, reject) => {
      if (!keys || keys.length === 0) {
        storage.clear(function (err) {
          if (err)
            reject(calls.setError('Clearing of local database failed', calls.service.CLIENT));
          resolve('Local database successfully cleared');
        });
      } else {
        let errors = [];
        for (let i = 0; i < keys.length; i++) {
          // delete keys from function arguments
          storage.del(keys[i], function (err) {
            if (err) errors.push(keys[i]);
          });
        }

        // resolve if no error found when deleting objects
        if (errors.length === 0)
          resolve('The selected keys were deleted successfully');
        else
          reject(calls.setError(`Keys ${errors} failed to be deleted from the local database`, calls.service.CLIENT));
      }
    });
  };

  // append db methods to calls
  calls.db = db;

  //------------------------------------------------------------------------------------------
  // RESPONSE HANDLING
  //------------------------------------------------------------------------------------------

  /**
	 * Service identifier.
	 *
	 * @type {{VOTING: number, CLIENT: number, DISCOVERY: number}}
	 */
  calls.service = {
    CLIENT: 0,
    VOTING: 1,
    DISCOVERY: 2,
  };

  /**
	 * Creates a universal response for every call in the voting library.
	 *
	 * @param {Object/String/null} message - Message to be presented to the user with the
	 * 										 response or explaining the state of an operation
	 * @param {Object/null} error - Error to be handled (types: string, axios (voting,
	 * 								discovery), other)
	 * @returns {Object} Response to be output by the library
	 */
  calls.buildResponse = function(message, error) {
    // response variable
    let response = {};
    let service;

    // set code
    if (error === null)
      response.code = 200;

    // parse and set error (types: string, axios (voting, discovery), other)
    try {
      if (error !== null) {
        if (typeof error === 'object' && 'data' in error && 'service' in error) {
          // set service and error
          service = error.service;
          error = error.data;

          // parse error by type
          switch (typeof error) {
          case 'string':
            // parse error data (string)
            response.error = error;
            break;
          case 'object':
            // parse error data (axios)
            if ('response' in error && error.response !== undefined) {
              // set code and error (voting, discovery)
              response.code = error.response.status;
              switch (service) {
              case calls.service.DISCOVERY:
                response.error = error.response.data.message;
                break;
              case calls.service.VOTING:
                if (message === null) message = error.response.data.message;
                response.error = error.response.data.error;
                break;
              default:
                response.error = error.response.statusText;
                break;
              }
            } else if ('code' in error && error.code === 'ECONNREFUSED') {
              response.error = 'Could not connect to server';
            } else {
              response.error = error;
            }
            break;
          default:
            response.error = error;
            break;
          }
        } else {
          response.error = error;
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    // set message
    if (message === null && error !== null) {
      switch (service) {
      case calls.service.CLIENT:
        response.message = 'Error: Client Library';
        break;
      case calls.service.VOTING:
        response.message = 'Error: Voting Server';
        break;
      case calls.service.DISCOVERY:
        response.message = 'Error: Discovery Service';
        break;
      default:
        response.message = 'Error';
        break;
      }
    } else if (message !== null) {
      response.message = message;
    }

    return response;
  };

  /**
	 * Universal error generator for the voting client library.
	 *
	 * @param {String/Object} error - Error to be handled
	 * @param {number} service - Service that caused the error
	 *
	 * @returns {{data: Object, service: number}} Error
	 */
  calls.setError = function(error, service) {
    // return error if already built
    if (typeof error === 'object' && 'data' in error && 'service' in error)
      return error;

    return {
      'data': error,
      'service': service,
    };
  };

  return calls;
})();

module.exports = services;
