/* eslint-disable react/jsx-no-bind,no-nested-ternary,jsx-a11y/interactive-supports-focus*/
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { formatDistanceToNow } from 'date-fns';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import ImmutablePureComponent from 'react-immutable-pure-component';
import IconButton from './icon_button';
import trust from '../../images/trust.png';
import mistrust from '../../images/mistrust.png';
import trusted from '../../images/trusted.png';
import mistrusted from '../../images/mistrusted.png';
import info from '../../images/info.png';
import infoCascade from '../../images/info1.gif';
import DropdownMenuContainer from '../containers/dropdown_menu_container';
import { me, isStaff } from '../initial_state';
import { wordsCount } from '../eunomia/lib';

const messages = defineMessages({
  delete: { id: 'status.delete', defaultMessage: 'Delete' },
  redraft: { id: 'status.redraft', defaultMessage: 'Delete & re-draft' },
  direct: { id: 'status.direct', defaultMessage: 'Direct message @{name}' },
  mention: { id: 'status.mention', defaultMessage: 'Mention @{name}' },
  mute: { id: 'account.mute', defaultMessage: 'Mute @{name}' },
  block: { id: 'account.block', defaultMessage: 'Block @{name}' },
  reply: { id: 'status.reply', defaultMessage: 'Reply' },
  share: { id: 'status.share', defaultMessage: 'Share' },
  more: { id: 'status.more', defaultMessage: 'More' },
  replyAll: { id: 'status.replyAll', defaultMessage: 'Reply to thread' },
  reblog: { id: 'status.reblog', defaultMessage: 'Boost' },
  reblog_private: { id: 'status.reblog_private', defaultMessage: 'Boost to original audience' },
  cancel_reblog_private: { id: 'status.cancel_reblog_private', defaultMessage: 'Unboost' },
  cannot_reblog: { id: 'status.cannot_reblog', defaultMessage: 'This post cannot be boosted' },
  favourite: { id: 'status.favourite', defaultMessage: 'Favourite' },
  bookmark: { id: 'status.bookmark', defaultMessage: 'Bookmark' },
  removeBookmark: { id: 'status.remove_bookmark', defaultMessage: 'Remove bookmark' },
  open: { id: 'status.open', defaultMessage: 'Expand this status' },
  report: { id: 'status.report', defaultMessage: 'Report @{name}' },
  muteConversation: { id: 'status.mute_conversation', defaultMessage: 'Mute conversation' },
  unmuteConversation: { id: 'status.unmute_conversation', defaultMessage: 'Unmute conversation' },
  pin: { id: 'status.pin', defaultMessage: 'Pin on profile' },
  unpin: { id: 'status.unpin', defaultMessage: 'Unpin from profile' },
  embed: { id: 'status.embed', defaultMessage: 'Embed' },
  admin_account: { id: 'status.admin_account', defaultMessage: 'Open moderation interface for @{name}' },
  admin_status: { id: 'status.admin_status', defaultMessage: 'Open this status in the moderation interface' },
  copy: { id: 'status.copy', defaultMessage: 'Copy link to status' },
  blockDomain: { id: 'account.block_domain', defaultMessage: 'Block domain {domain}' },
  unblockDomain: { id: 'account.unblock_domain', defaultMessage: 'Unblock domain {domain}' },
  unmute: { id: 'account.unmute', defaultMessage: 'Unmute @{name}' },
  unblock: { id: 'account.unblock', defaultMessage: 'Unblock @{name}' },
});

const NUDGE_READY = false;

const obfuscatedCount = count => {
  if (count < 0) {
    return 0;
  } else if (count <= 1) {
    return count;
  } else {
    return '1+';
  }
};

const mapStateToProps = (state, { status }) => ({
  relationship: state.getIn(['relationships', status.getIn(['account', 'id'])]),
});

export default @connect(mapStateToProps)
@injectIntl
class StatusActionBar extends ImmutablePureComponent {

  state = {
    infoOpen: false,
    voting: false,
    myVote: null,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  static propTypes = {
    status: ImmutablePropTypes.map.isRequired,
    eunomia: ImmutablePropTypes.map,
    cascade: ImmutablePropTypes.map,
    stats: ImmutablePropTypes.map,
    onVoteRequest: PropTypes.func,
    onCascadeToggle: PropTypes.func,
    relationship: ImmutablePropTypes.map,
    onReply: PropTypes.func,
    onFavourite: PropTypes.func,
    onReblog: PropTypes.func,
    onDelete: PropTypes.func,
    onDirect: PropTypes.func,
    onMention: PropTypes.func,
    onMute: PropTypes.func,
    onUnmute: PropTypes.func,
    onBlock: PropTypes.func,
    onUnblock: PropTypes.func,
    onBlockDomain: PropTypes.func,
    onUnblockDomain: PropTypes.func,
    onReport: PropTypes.func,
    onEmbed: PropTypes.func,
    onMuteConversation: PropTypes.func,
    onPin: PropTypes.func,
    onBookmark: PropTypes.func,
    withDismiss: PropTypes.bool,
    intl: PropTypes.object.isRequired,
  };

  // Avoid checking props that are functions (and whose equality will always
  // evaluate to false. See react-immutable-pure-component for usage.
  updateOnProps = [
    'status',
    'eunomia',
    'cascade',
    'relationship',
    'withDismiss',
    'stats',
  ];

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromProps(nextProps, prevState) {
    const { infoOpen, voting, myVote } = prevState;
    const { eunomia } = nextProps;
    if (voting && eunomia !== null && myVote !== null) {
      const voted = eunomia.get('voted', false);
      if (voted) {
        return { infoOpen, voting: false, myVote: null };
      }
    }
    return null;
  }

  handleReplyClick = () => {
    if (me) {
      this.props.onReply(this.props.status, this.context.router.history);
    } else {
      this._openInteractionDialog('reply');
    }
  };

  handleShareClick = () => {
    navigator.share({
      text: this.props.status.get('search_index'),
      url: this.props.status.get('url'),
    }).catch((e) => {
      if (e.name !== 'AbortError') console.error(e);
    });
  };

  handleFavouriteClick = () => {
    if (me) {
      this.props.onFavourite(this.props.status);
    } else {
      this._openInteractionDialog('favourite');
    }
  };

  handleReblogClick = e => {
    if (me) {
      this.props.onReblog(this.props.status, e);
    } else {
      this._openInteractionDialog('reblog');
    }
  };

  _openInteractionDialog = type => {
    window.open(`/interact/${this.props.status.get('id')}?type=${type}`, 'mastodon-intent', 'width=445,height=600,resizable=no,menubar=no,status=no,scrollbars=yes');
  };

  handleBookmarkClick = () => {
    this.props.onBookmark(this.props.status);
  };

  handleDeleteClick = () => {
    this.props.onDelete(this.props.status, this.context.router.history);
  };

  handleRedraftClick = () => {
    this.props.onDelete(this.props.status, this.context.router.history, true);
  };

  handlePinClick = () => {
    this.props.onPin(this.props.status);
  };

  handleMentionClick = () => {
    this.props.onMention(this.props.status.get('account'), this.context.router.history);
  };

  handleDirectClick = () => {
    this.props.onDirect(this.props.status.get('account'), this.context.router.history);
  };

  handleMuteClick = () => {
    const { status, relationship, onMute, onUnmute } = this.props;
    const account = status.get('account');

    if (relationship && relationship.get('muting')) {
      onUnmute(account);
    } else {
      onMute(account);
    }
  };

  handleBlockClick = () => {
    const { status, relationship, onBlock, onUnblock } = this.props;
    const account = status.get('account');

    if (relationship && relationship.get('blocking')) {
      onUnblock(account);
    } else {
      onBlock(status);
    }
  };

  handleBlockDomain = () => {
    const { status, onBlockDomain } = this.props;
    const account = status.get('account');

    onBlockDomain(account.get('acct').split('@')[1]);
  };

  handleUnblockDomain = () => {
    const { status, onUnblockDomain } = this.props;
    const account = status.get('account');

    onUnblockDomain(account.get('acct').split('@')[1]);
  };

  handleOpen = () => {
    this.context.router.history.push(`/statuses/${this.props.status.get('id')}`);
  };

  handleEmbed = () => {
    this.props.onEmbed(this.props.status);
  };

  handleReport = () => {
    this.props.onReport(this.props.status);
  };

  handleConversationMuteClick = () => {
    this.props.onMuteConversation(this.props.status);
  };

  handleCopy = () => {
    const url      = this.props.status.get('url');
    const textarea = document.createElement('textarea');

    textarea.textContent    = url;
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);

    try {
      textarea.select();
      document.execCommand('copy');
    } catch (e) {

    } finally {
      document.body.removeChild(textarea);
    }
  };

  updateTrust = (trust) => {
    this.props.onVoteRequest(this.props.eunomia, this.props.status, trust);
    this.setState({ voting: true, myVote: trust });
  };

  handleTrust = (e) => {
    e.preventDefault();
    this.updateTrust(true);
  };

  handleMistrust = (e) => {
    e.preventDefault();
    this.updateTrust(false);
  };

  handleInfoView = () => {
    this.setState({ infoOpen: !this.state.infoOpen });
  };

  handleCascadeView = (e) => {
    e.preventDefault();
    this.props.onCascadeToggle();
  };

  render () {
    const { status, relationship, intl, withDismiss, eunomia, cascade, stats } = this.props;

    const mutingConversation = status.get('muted');
    const anonymousAccess    = !me;
    const publicStatus       = ['public', 'unlisted'].includes(status.get('visibility'));
    const account            = status.get('account');

    let menu = [];
    let reblogIcon = 'retweet';
    let replyIcon;
    let replyTitle;

    menu.push({ text: intl.formatMessage(messages.open), action: this.handleOpen });

    if (publicStatus) {
      menu.push({ text: intl.formatMessage(messages.copy), action: this.handleCopy });
      menu.push({ text: intl.formatMessage(messages.embed), action: this.handleEmbed });
    }

    menu.push({ text: intl.formatMessage(status.get('bookmarked') ? messages.removeBookmark : messages.bookmark), action: this.handleBookmarkClick });
    menu.push(null);

    if (status.getIn(['account', 'id']) === me || withDismiss) {
      menu.push({ text: intl.formatMessage(mutingConversation ? messages.unmuteConversation : messages.muteConversation), action: this.handleConversationMuteClick });
      menu.push(null);
    }

    if (status.getIn(['account', 'id']) === me) {
      if (publicStatus) {
        menu.push({ text: intl.formatMessage(status.get('pinned') ? messages.unpin : messages.pin), action: this.handlePinClick });
      } else {
        if (status.get('visibility') === 'private') {
          menu.push({ text: intl.formatMessage(status.get('reblogged') ? messages.cancel_reblog_private : messages.reblog_private), action: this.handleReblogClick });
        }
      }

      menu.push({ text: intl.formatMessage(messages.delete), action: this.handleDeleteClick });
      menu.push({ text: intl.formatMessage(messages.redraft), action: this.handleRedraftClick });
    } else {
      menu.push({ text: intl.formatMessage(messages.mention, { name: account.get('username') }), action: this.handleMentionClick });
      menu.push({ text: intl.formatMessage(messages.direct, { name: account.get('username') }), action: this.handleDirectClick });
      menu.push(null);

      if (relationship && relationship.get('muting')) {
        menu.push({ text: intl.formatMessage(messages.unmute, { name: account.get('username') }), action: this.handleMuteClick });
      } else {
        menu.push({ text: intl.formatMessage(messages.mute, { name: account.get('username') }), action: this.handleMuteClick });
      }

      if (relationship && relationship.get('blocking')) {
        menu.push({ text: intl.formatMessage(messages.unblock, { name: account.get('username') }), action: this.handleBlockClick });
      } else {
        menu.push({ text: intl.formatMessage(messages.block, { name: account.get('username') }), action: this.handleBlockClick });
      }

      menu.push({ text: intl.formatMessage(messages.report, { name: account.get('username') }), action: this.handleReport });

      if (account.get('acct') !== account.get('username')) {
        const domain = account.get('acct').split('@')[1];

        menu.push(null);

        if (relationship && relationship.get('domain_blocking')) {
          menu.push({ text: intl.formatMessage(messages.unblockDomain, { domain }), action: this.handleUnblockDomain });
        } else {
          menu.push({ text: intl.formatMessage(messages.blockDomain, { domain }), action: this.handleBlockDomain });
        }
      }

      if (isStaff) {
        menu.push(null);
        menu.push({ text: intl.formatMessage(messages.admin_account, { name: account.get('username') }), href: `/admin/accounts/${status.getIn(['account', 'id'])}` });
        menu.push({ text: intl.formatMessage(messages.admin_status), href: `/admin/accounts/${status.getIn(['account', 'id'])}/statuses/${status.get('id')}` });
      }
    }

    if (status.get('visibility') === 'direct') {
      reblogIcon = 'envelope';
    } else if (status.get('visibility') === 'private') {
      reblogIcon = 'lock';
    }

    if (status.get('in_reply_to_id', null) === null) {
      replyIcon = 'reply';
      replyTitle = intl.formatMessage(messages.reply);
    } else {
      replyIcon = 'reply-all';
      replyTitle = intl.formatMessage(messages.replyAll);
    }

    const shareButton = ('share' in navigator) && publicStatus && (
      <IconButton className='status__action-bar-button' title={intl.formatMessage(messages.share)} icon='share-alt' onClick={this.handleShareClick} />
    );

    const trustIcon = () => {
      let imgSrc = trust;
      let trustsStr = '';
      let voted = false;
      if (eunomia) {
        const trusts = eunomia.getIn(['votes', 'trusts'], null);
        try {
          const count = parseInt(trusts);
          trustsStr = !isNaN(count) ? `${count}` : '0';
        } catch {}
        voted = eunomia.get('voted', false);
        if (voted) {
          const _trusted = eunomia.get('trusted', false);
          if (_trusted) {
            imgSrc = trusted;
          }
        }
      }
      if (!voted && this.state.voting && this.state.myVote !== null && this.state.myVote === true) {
        imgSrc = trusted;
        if (trustsStr === '') {
          trustsStr = '1';
        } else {
          trustsStr = parseInt(trustsStr) + 1;
        }
      }
      return (

        <div className='eunomia-menu-icon-wrapper'>
          <img src={imgSrc} alt='' className='eunomia-menu-icon' />
          <div
            className='eunomia-menu-icon-counter'
            id='eunomia-total-trusts-counter'
            // style={{ display: this.state.infoOpen ? 'inherit' : 'none' }}
          >
            { trustsStr }
          </div>
        </div>
      );
    };

    const mistrustIcon = () => {
      let imgSrc = mistrust;
      let mistrustsStr = '';
      let voted = false;
      if (eunomia) {
        const mistrusts = eunomia.getIn(['votes', 'mistrusts'], null);
        try {
          const count = parseInt(mistrusts);
          mistrustsStr = !isNaN(count) ? `${count}` : '0';
        } catch {}
        voted = eunomia.get('voted', false);
        if (voted) {
          const _trusted = eunomia.get('trusted', true);
          if (!_trusted) {
            imgSrc = mistrusted;
          }
        }
      }
      if (!voted && this.state.voting && this.state.myVote !== null && this.state.myVote === false) {
        imgSrc = mistrusted;
        if (mistrustsStr === '') {
          mistrustsStr = '1';
        } else {
          mistrustsStr = parseInt(mistrustsStr) + 1;
        }
      }
      return (

        <div className='eunomia-menu-icon-wrapper'>
          <img
            src={imgSrc}
            alt=''
            className='eunomia-menu-icon'
          />
          <div
            className='eunomia-menu-icon-counter'
            id='eunomia-total-mistrusts-counter'
            // style={{ display: this.state.infoOpen ? 'inherit' : 'none' }}
          >
            { mistrustsStr }
          </div>
        </div>
      );
    };

    const indicators = () => {
      let imgSrc = info;
      if (eunomia && cascadePostsCount() > 0) {
        // return (
        //   <div
        //     className='eunomia-info-cascade-icon eunomia-menu-icon'
        //     style={{ marginRight: -6, marginLeft: 3, marginTop: -1, padding: 0 }}
        //   />
        // );
        imgSrc = infoCascade;
      }
      return (
        <div className='eunomia-menu-icon-wrapper' style={{ marginRight: -6, marginLeft: 3, marginTop: -1, padding: 0 }}>
          <img src={imgSrc} alt='' className='eunomia-menu-icon' />
        </div>
      );
    };

    const showEunomiaToolbox = () => {
      // we can display the stats, we have them from Mastodon, we just cannot allow voting till we get the eunomia id.
      return true;
    };

    const canNotVote = () => {
      if (!eunomia) {
        return true;
      }
      if (this.state.voting) {
        return true;
      }
      const voted = eunomia.get('voted', false);
      if (voted) {
        return true;
      }
      return status.getIn(['account', 'id']) === me;
    };

    const canVote = () => !canNotVote();

    const showNudge = () => {
      if (!NUDGE_READY) {
        return false;
      }
      if (canNotVote()) {
        return false;
      }
      // also check weekly votes
      if (!stats) {
        return false;
      }
      return true;
    };

    const cascadePostsCount = () => {
      if (!cascade) {
        return 0;
      }
      if (!eunomia) {
        return 0;
      }
      if (eunomia.get('processed', 'no') !== 'yes') {
        return 0;
      }
      return cascade.size -1;
    };

    return (
      <div>
        <div className='eunomia-nudge' style={{ display: showNudge() ? 'inherit': 'none' }}>
          Would you like to try rating this post’s trustworthiness by pressing one of the buttons below?
        </div>
        <div className='status__action-bar'>
          <div className='eunomia-action-bar-wrapper' style={{ display: showEunomiaToolbox() ? 'flex': 'none' }}>
            <IconButton className='status__action-bar-button eunomia-icon-button' style={{ marginRight: '0px!important' }} size={19} title={'EUNOMIA indicators'} icon={indicators} onClick={this.handleInfoView} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                className='status__action-bar-button eunomia-icon-button'
                id='eunomia-trust-button'
                size={19}
                disabled={canNotVote()}
                title={'Trust this status'}
                icon={trustIcon}
                onClick={this.handleTrust}
              />
              { canVote() &&
                <a
                  className={'voting-button-text-link'}
                  onClick={this.handleTrust}
                  href='/#'
                  title='I trust this'
                >
                  I trust this

                </a>
              }
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                className='status__action-bar-button eunomia-icon-button'
                id='eunomia-mistrust-button'
                size={20}
                disabled={canNotVote()}
                title={'Mistrust this status'}
                icon={mistrustIcon}
                onClick={this.handleMistrust}
              />
              { canVote() &&
                <a
                  className={'voting-button-text-link'}
                  onClick={this.handleMistrust}
                  href='/#'
                  title="I don't trust this"
                >
                  I don't trust this
                </a>
              }
            </div>
          </div>
          <div className='status__action-bar__counter'>
            <IconButton
              className='status__action-bar-button'
              title={replyTitle}
              icon={status.get('in_reply_to_account_id') === status.getIn(['account', 'id']) ? 'reply' : replyIcon}
              onClick={this.handleReplyClick}
            />
            <span className='status__action-bar__counter__label' >{obfuscatedCount(status.get('replies_count'))}</span>
          </div>
          <IconButton
            className='status__action-bar-button'
            disabled={!publicStatus}
            active={status.get('reblogged')}
            pressed={status.get('reblogged')}
            title={!publicStatus ? intl.formatMessage(messages.cannot_reblog) : intl.formatMessage(messages.reblog)}
            icon={reblogIcon}
            onClick={this.handleReblogClick}
          />
          <IconButton
            className='status__action-bar-button star-icon'
            animate
            active={status.get('favourited')}
            pressed={status.get('favourited')}
            title={intl.formatMessage(messages.favourite)}
            icon='star'
            onClick={this.handleFavouriteClick}
          />
          {shareButton}
          <div className='status__action-bar-dropdown'>
            <DropdownMenuContainer disabled={anonymousAccess} status={status} items={menu} icon='ellipsis-h' size={18} direction='right' title={intl.formatMessage(messages.more)} />
          </div>
        </div>
        <div className='eunomia-info-view' style={{ display: this.state.infoOpen ? 'flex' : 'none' }}>
          <div>
            <div style={{ float: 'right' }} role='button' onClick={this.handleInfoView} className='eunomia-indicators-close' />
          </div>
          <div>
            Account Age: <span style={{ fontWeight: 'bolder' }}>{formatDistanceToNow(new Date(status.getIn(['account', 'created_at']))).replace('about ', '')}</span>
          </div>
          <div>
            Connections: <span style={{ fontWeight: 'bolder' }}>{status.getIn(['account', 'followers_count'])} followers / {status.getIn(['account', 'following_count'])} following</span>
          </div>
          <div>
            Posts: <span style={{ fontWeight: 'bolder' }}>{status.getIn(['account', 'statuses_count'])}</span>
          </div>
          {  eunomia && eunomia.get('cascade_id', null) !== null && cascadePostsCount() > 0 && wordsCount(status.get('content')) > 6 ? (
            <div style={{ marginTop: 10 }}>
              <a
                href={`${window.location.href}/#`}
                className='eunomia-cascade-link'
                title='Similar posts'
                onClick={this.handleCascadeView}
              >
                Show other similar posts ({cascadePostsCount()})
              </a>
            </div>
          ) : null }
        </div>
      </div>
    );
  }

}
