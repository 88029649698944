import axios from 'axios';
import votingOld from './voting_old/lib';
import votingNew from './voting_new/lib';
import { domainName, accessToken } from 'mastodon/initial_state';
import { getMotd } from './motd';
import { EUNOMIA_INIT, ACCOUNTS_DB_KEY, TOKEN_DURATION, EUNOMIA_STATS_IMPORT, NEW_VOTING, EUNOMIA_VERSION } from './constants';
import { getEunomiaHeaders } from './actions';
import { myId } from '../initial_state';

export { getMotd };
const { db } = NEW_VOTING ? votingNew: votingOld;

export const plainDomain = (url) => {
  if (url) {
    const regex = /(https|http):\/\//g;
    return url.replace(regex, '').split('/')[0];
  }
  return url;
};

export const htmlToText = (html) => {
  let temp = document.createElement('div');
  temp.innerHTML = html;
  return temp.textContent || temp.innerText || '';
};

export const wordsCount = (str) => {
  return htmlToText(str).split(' ')
    .filter(function(n) {
      return n !== '';
    })
    .length;
};

export const isValidURL = (str) => {
  const res = str.match(
    /(^(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-z]{1,6}\b([-a-zA-Z0-9@:%_.~#?&/=]*))|(^(http(s)?:\/\/)(localhost|(\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b))(:[0-9]*)?)/g,
  );
  return res !== null;
};

const _fetchToken  = (eunomiaUri, snUri, snToken, resolve, reject, force = false) => {
  if (!window.sessionStorage.getItem('GT') || force) {
    window.sessionStorage.setItem('GT', '1');
    const snDomain = plainDomain(snUri);
    axios
      .get(`${eunomiaUri}/eunomia/token?auth_provider_uri=${snDomain}&access_token=${snToken}`)
      .then(({ data }) => {
        const responseData = data.data;
        if (responseData && responseData.token) {
          const created = new Date();
          const dbEntry = {};
          const dbValue = { token: responseData.token, created, srcToken: snToken };
          dbEntry[eunomiaUri] = dbValue;
          // eslint-disable-next-line promise/catch-or-return
          db.save(ACCOUNTS_DB_KEY, dbEntry).then().catch().finally(() => {
            if (window.sessionStorage.getItem('GT')) {
              window.sessionStorage.removeItem('GT');
            }
            resolve(dbValue);
          });
        } else {
          if (window.sessionStorage.getItem('GT')) {
            window.sessionStorage.removeItem('GT');
          }
          reject({ message: 'Could not get a token :(' });
        }
      }).catch((reason) => {
        if (window.sessionStorage.getItem('GT')) {
          window.sessionStorage.removeItem('GT');
        }
        reject(reason);
      });
  } else {
    reject({ 'message': 'already fetching' });
  }
};

const _checkToken = (eunomiaUri) => new Promise(((resolve) => {
  try {
    db.get(ACCOUNTS_DB_KEY).then((entry) => {
      if (entry && entry[eunomiaUri]) {
        const { token, created } = entry[eunomiaUri];
        const _created = new Date(created);
        _created.setTime(_created.getTime() + TOKEN_DURATION - 120);
        const now = new Date();
        if (_created < now) {
          // expired, or expires in less than two minutes, get a new one
          resolve(null);
        } else {
          resolve({ token, created });
        }
      } else {
        resolve(null);
      }
    }).catch(() => resolve(null));
  } catch (_) {
    resolve(null);
  }
}));

export const getToken = (eunomiaUrl, snUrl, snToken, force = false) => new Promise(((resolve, reject) => {
  _checkToken(eunomiaUrl).then((existing) => {
    if (existing) {
      resolve(existing);
    } else {
      _fetchToken(eunomiaUrl, snUrl, snToken, resolve, reject, force);
    }
  }).catch(() => {
    _fetchToken(eunomiaUrl, snUrl, snToken, resolve, reject, force);
  });
}));

export const clearDb = ()  => new Promise(((resolve, reject) => {
  db.clear([]).then((result => resolve(result))).catch((reason) => reject(reason));
}));

// New voting lib:
export const initVotingNew = (nodeEndpoint, votingEndpoint, eunomiaToken, eunomiaUserId) => new Promise(((resolve, reject) => {
  const domain = plainDomain(domainName);
  const snUrl = `https://${domain}`;
  const secret = 'n0t@$3cr3t';
  votingNew.initialize(nodeEndpoint, votingEndpoint, eunomiaToken, eunomiaUserId, false, secret).then((loginResult) => {
    resolve(loginResult);
  }).catch(() => {
    votingNew.initialize(nodeEndpoint, votingEndpoint, eunomiaToken, eunomiaUserId, true, secret).then((registerResult) => {
      resolve(registerResult);
    }).catch((reason) => {
      if (reason && reason.code && reason.code === 401) {
        // invalid token ?  let's try one more time with a new one
        _fetchToken(nodeEndpoint, snUrl, accessToken, (data) => {
          if (data && data.token) {
            votingNew.initialize(nodeEndpoint, votingEndpoint, data.token, eunomiaUserId, false, secret).then((loginResult) => {
              resolve(loginResult);
            }).catch(() => {
              votingNew.initialize(nodeEndpoint, votingEndpoint, data.token, eunomiaUserId, true, secret).then((registerResult) => {
                resolve(registerResult);
              }).catch((reason3) => {
                reject(reason3);
              });
            });
          } else {
            reject(reason);
          }
        }, (reason2) => {
          reject(reason2);
        }, true);
      } else {
        reject(reason);
      }
    });
  });
}));

// Old voting lib:
export const initVotingOld = (eunomiaToken, eunomiaUserId) => new Promise(((resolve, reject) => {
  const secret = 'n0t@$3cr3t';
  const domain = plainDomain(domainName);
  const storageUrl = `https://${domain}/storage`;
  const snUrl = `https://${domain}`;
  votingOld.initialize(snUrl, eunomiaToken, eunomiaUserId, secret).then((loginResult) => {
    resolve(loginResult);
  }).catch((reason) => {
    if (reason && reason.code && reason.code === 401) {
      // invalid token ?  let's try one more time with a new one
      _fetchToken(storageUrl, snUrl, accessToken, (data) => {
        if (data && data.token) {
          votingOld.initialize(snUrl, data.token, eunomiaUserId, secret).then((initResults) => {
            resolve(initResults);
          }).catch((reason1) => {
            reject(reason1);
          });
        } else {
          reject(reason);
        }
      }, (reason2) => {
        reject(reason2);
      }, true);
    } else {
      reject(reason);
    }
  });
}));

const checkVersion =(cb) => {
  const url ='/eunomia/api/version';
  axios.get(url).then(({ data }) => {
    if (data && data.version) {
      const currentVersion = data.version;
      db.get(ACCOUNTS_DB_KEY).then((entry) => {
        if (!entry || (entry && entry.version && entry.version !== currentVersion)) {
          db.clear([]).then(() => {
            db.save(EUNOMIA_VERSION, data).then(() => cb()).catch(() => cb());
          }).catch(() => {
            db.save(EUNOMIA_VERSION, data).then(() => cb()).catch(() => cb());
          });
        } else {
          db.save(EUNOMIA_VERSION, data).then(() => cb()).catch(() => cb());
        }
      }).catch(() => {
        db.save(EUNOMIA_VERSION, data).then(() => cb()).catch(() => cb());
      });
    } else {
      cb();
    }
  }).catch(() => cb());
};

export const getEunomiaToken = (force=false) => new Promise((resolve, reject) => {
  const domain = plainDomain(domainName);
  const snUri = `https://${domain}`;
  const eunomiaUri = `https://${domain}/storage`;
  getToken(eunomiaUri, snUri, accessToken, force).then((data) => {
    resolve(data);
  }).catch((reason) => {
    reject(reason);
  });
});

// Old voting lib:
const eunomiaInitOld = () => dispatch => {
  checkVersion(() => {
    getEunomiaToken(true).then((data) => {
      if (data) {
        const { token } = data;
        // eslint-disable-next-line promise/catch-or-return,no-console
        initVotingOld(token, myId).then().catch((reason) => console.log(reason)).finally(() => {
          dispatch({ type: EUNOMIA_INIT });
          const domain = plainDomain(domainName);
          const snUri = `https://${domain}`;
          getEunomiaHeaders().then((headers) => {
            axios.get(`${snUri}/eunomia/api/me`, { headers }).then((response) => {
              if (response.data && response.data.stats) {
                dispatch({ type: EUNOMIA_STATS_IMPORT, payload: response.data.stats });
              }
            }).catch(console.warn);
          }).catch();
        });
      }
    }).catch((reason) => {
      // eslint-disable-next-line no-console
      console.log(reason);
    });
  });
};

const _onGotToken = (data, dispatch) => {
  const { token } = data;
  const domain = plainDomain(domainName);
  const nodeEndpoint = `https://${domain}/storage`;
  const votingEndpoint = `https://${domain}/voting`;
  // eslint-disable-next-line no-console,promise/catch-or-return
  initVotingNew(nodeEndpoint, votingEndpoint, token, myId).then(() => {
    // eslint-disable-next-line no-console
  }).catch((reason) => {
    console.warn(reason);
    votingNew.clear([]).then(() => {
      initVotingNew(nodeEndpoint, votingEndpoint, token, myId).then(() => {}).catch(()=>{});
    }).catch(()=>{});
  }).finally(() => {
    dispatch({ type: EUNOMIA_INIT });
    const snUri = `https://${domain}`;
    getEunomiaHeaders().then((headers) => {
      axios.get(`${snUri}/eunomia/api/me`, { headers }).then((response) => {
        if (response.data && response.data.stats) {
          dispatch({ type: EUNOMIA_STATS_IMPORT, payload: response.data.stats });
        }
      }).catch(console.warn);
    }).catch();
  });
};

//
// New voting lib:
const eunomiaInitNew = () => dispatch => {
  checkVersion(() => {
    getEunomiaToken().then((data) => {
      if (data) {
        _onGotToken(data, dispatch);
      }
    }).catch(() => {
      setTimeout(() => {
        getEunomiaToken().then((data) => {
          if (data) {
            _onGotToken(data, dispatch);
          }
        }).catch(()=>{});
      }, 3000);
    });
  });
};

export const handleItem = (item) => {
  let result = { ...item };
  function traverse(jsonObj) {
    if (Object.prototype.toString.call(jsonObj) === '[object String]') {
      if (jsonObj.startsWith('http:') && jsonObj.includes(domainName) && window.location.protocol.startsWith('https')) {
        jsonObj = jsonObj.replace('http', 'https');
      }
    } else {
      if (jsonObj !== null && typeof jsonObj === 'object') {
        Object.entries(jsonObj).forEach(([key, value]) => {
          // key is either an array index or object key
          jsonObj[key] = traverse(value);
        });
      }
    }
    return jsonObj;
  }
  return traverse(result);
};

export const eunomiaInit = NEW_VOTING ? eunomiaInitNew : eunomiaInitOld;
