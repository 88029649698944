'use strict';

const axios = require('axios');
const crypto = require('./cryptography.js');
const services = require('./services.js');

let library = (function() {

  const VOTING_PATH = '/voting/voting';
  // nginx proxy:
  //  location /voting {
  //      proxy_pass http://146.193.69.131:8100/api;
  //      ...
  //  }

  // paths for the API calls made by this library
  const BALLOT_PATH = '/ballot';
  const FEATURES_PATH = '/features';
  const PUBLIC_VOTE_PATH = '/vote';
  const ANONYMOUS_VOTE_PATH = '/anonymize';

  // default mix-net size (including first node)
  const MIX_SIZE = 3;

  // dictionary of methods exported by this module
  let calls = {};

  //------------------------------------------------------------------------------------------
  // MAIN FUNCTIONS (EXPORTED)
  //------------------------------------------------------------------------------------------

  /**
	 * This call must be made whenever there is a new user logging in or signing up.
	 * New information will be saved locally and used later.
	 * The initialization process encompasses all of these procedures (chained promises):
	 *
	 * 1. Get Node Information (including Voting Server ID)
	 * 2. Set Discovery Service URL (same as ESN URL)
	 * 3. Look for Voting Server credentials saved locally
	 * 4. If these do not exist, query Discovery Service to get Voting Server credentials
	 * 5. Look for the EUNOMIA User's credentials saved locally
	 * 6. If these do not exist, call Voting Server to do one of the following:
	 * 		6.1 Register a new voter
	 * 		6.2 Login the voter
	 * 7. Save the association between voter and node as well as any other important information
	 *	  regarding these two entities (such as endpoint and keys)
	 *
	 * @param {String} endpoint - Endpoint address of the EUNOMIA Services Node where the Discovery and Voting
	 * 							  Services are running (e.g. http://146.193.69.131/)
	 * @param {String} accessToken - EUNOMIA Access Token for the user, retrieved through EUNOMIA's infrastructure
	 * @param {String} userId - EUNOMIA user ID
	 * @param {String} secret - Secret related to the user such as a password or the hashed password,
	 * 							works as long as the user is the only entity knowing the secret
	 *
	 * @return {Promise<Object>} State of the initialization process or Error
	 */
  // eslint-disable-next-line no-unused-vars
  calls.initialize = function(endpoint, accessToken, userId, secret) {
    // voting server id
    let votingId;

    // chain promises
    return services.getNodeInfo(endpoint).then(info => {
      // set voting server id
      votingId = info.votingServiceId;

      // set discovery url
      return services.discovery.setUrl(endpoint);
    }).then(() => services.db.get(votingId)).then(server => {
      // if node not found, search services for the node id
      if (server === null) {
        return services.discovery.getVotingServer(accessToken, votingId);
      }

      // return server to be updated locally
      return server;
    }).then(server => {
      // check if server exists and is live
      if ('live' in server && server.live.toString() === 'false') {
        return Promise.reject(services.setError(`Voting Server with ID ${votingId} is not active`, services.service.CLIENT));
      }

      // delete unnecessary keys (if they exist)
      if ('live' in server) {
        delete server.live;
      }

      // update endpoint
      let url = new URL(endpoint);
      url.pathname = VOTING_PATH;
      server.endpoint = url.toString();

      // save to database (return promise)
      return services.db.save(votingId, server);
    }).then(() => services.db.get(userId)).then(user => {
      // user not saved locally
      if (user === null) {
        // handle user here
        return {};
      }

      // return user to be updated locally
      return user;
    }).then(user => {
      // build user
      user.serverId = votingId;
      if (!user.ballots) {
        user.ballots = {};
      }

      // save locally
      return services.db.save(userId, user);
    }).then(() => {
      return Promise.resolve(services.buildResponse(`Initialization of user ${userId} was successful`, null));
    }).catch(error => Promise.reject(services.buildResponse(null, error)));
  };

  /**
	 * The DC UI calls this method to receive a ballot and the features that can be appended to it.
	 * The chained promises of these function are executed in the following order:
	 *
	 * 1. Check if voter exists
	 * 2. Check if the Voting Server for that voter also exists
	 * 3. Call the Voting Server to request a new ballot and user features
	 *
	 * @param {String} accessToken - EUNOMIA Access Token for the user, retrieved by EUNOMIA's infrastructure
	 * @param {String} postId - ID of the post that matches the ballot being returned
	 * @param {String} userId - ID of the user to request the features for
	 *
	 * @return {Promise<Object>} JSON with ballot and features or Error
	**/
  calls.getBallot = function(accessToken, postId, userId) {
    // declare initialization error
    const errorInit = services.setError(`User ${userId} was not initialized properly`, services.service.CLIENT);

    // chain promises
    return services.db.get(userId).then(user => {
      if (user === null)
        return Promise.reject(errorInit);

      return services.db.get(user.serverId);
    }).then(server => {
      if (server === null)
        return Promise.reject(errorInit);

      return server;
    }).then(server => {
      // build urls
      let ballotUrl = `${server.endpoint + BALLOT_PATH}?post_id=${postId}`;
      let featuresUrl = `${server.endpoint + FEATURES_PATH}?access_token=${accessToken}&user_id=${userId}`;

      // build both requests
      let request = [axios.get(ballotUrl), axios.get(featuresUrl)];

      // make request asynchronously
      return new Promise((resolve, reject) => {
        axios.all(request).then(responses => {
          // get manager key
          let managerKey = crypto.rsa.keyFromCertificate(server.manager);

          // verify signatures for both requests
          responses.forEach(response => {
            let signature = response.headers['x-signature'];
            if (!crypto.rsa.verify(response.data, signature, managerKey))
              reject(services.setError('Manager signature is invalid', services.service.VOTING));
          });

          // build library response
          let result = {
            'ballot': responses[0].data.data,
            'features': responses[1].data.data,
          };

          // return ballot + features
          resolve(services.buildResponse(result, null));
        }).catch(error => {
          reject(services.setError(error, services.service.VOTING));
        });
      });
    }).catch(error => {
      return Promise.reject(services.buildResponse(null, error));
    });
  };

  /**
	 * The DC UI calls this function to vote. This call is responsible for handling all processes
	 * related to the voting procedure, which may include generation of values, application
	 * of cryptographic functions and more.
	 * The chain of promises flows as follows:
	 *
	 * 1. Check if voter exists
	 * 2. Check if the Voting Server for that voter also exists
	 * 3. Reset Discovery Service URL to point to user's node
	 * 4. Obtain eligibility to vote
	 * 5. Encrypt the data being sent (ballot and features) through the mix network (if voting anonymously)
	 * 6. Call the Voting Server to handle the voting (and anonymization) process
	 *
	 * @param {String} accessToken - EUNOMIA Access Token for the user, retrieved through EUNOMIA's infrastructure
	 * @param {String} userId - ID of the user who is voting
	 * @param {Object} body - Body of the vote request which contains a ballot (key: ballot) and optionally features
	 * 						  (key: features); the ballot is an object represented as dictionary {post_id: <string>,
	 * 						  votes: <object>} and the features the voter would like to append to the ballot are also
	 * 						  are dictionary {"user_id": <string>, "features": <object>}; both of these are equal to
	 * 						  the object returned by the getBallot function but updated according to user preferences
	 * @param {Boolean} anonymous - Specifies if the vote operation is to be carried out publicly or anonymously
	 *
	 * @return {Promise<Object>} Server response or Error
	**/
  calls.vote = function(accessToken, userId, body, anonymous) {
    // define errors
    const errorInit = services.setError(`User ${userId} was not initialized properly`, services.service.CLIENT);
    const errorFormat = services.setError('Voting body has invalid format', services.service.CLIENT);

    // verify body format
    if (typeof body !== 'object' || !('ballot' in body))
      return Promise.reject(errorFormat);

    // verify ballot
    let ballot = body.ballot;
    if (typeof ballot !== 'object' || !('post_id' in ballot) || !('votes' in ballot))
      return Promise.reject(errorFormat);

    // verify features
    let features = null;
    if ('features' in body) {
      features = body.features;
      if (typeof features !== 'object' || !('user_id' in features) || !('features' in features))
        return Promise.reject(errorFormat);
    }

    // declare parameters
    let user, endpoint;

    // chain promises
    return services.db.get(userId).then(object => {
      if (object === null)
        return Promise.reject(errorInit);

      // set user variable
      user = object;

      return services.db.get(user.serverId);
    }).then(server => {
      if (server === null)
        return Promise.reject(errorInit);

      return server;
    }).then(server => {
      // set voting server endpoint
      endpoint = server.endpoint;

      // reset discovery url
      return services.discovery.setUrl(endpoint);
    }).then(() => {
      // obtain eligibility to vote
      return eligibility(endpoint, ballot);
    }).then(response => {
      // encrypt body if anonymous
      if (anonymous)
        return services.anonymize(accessToken, MIX_SIZE, user.serverId, response);

      // return body if not anonymous
      return response;
    }).then(body => {
      // build voting url
      let url = endpoint + (anonymous ? `${ANONYMOUS_VOTE_PATH}?access_token=${accessToken}` : PUBLIC_VOTE_PATH);

      // make vote request asynchronously
      return new Promise((resolve, reject) => {
        axios.post(url, body, {}).then(response => {
          if (!user.ballots) {
            user.ballots = {};
          }
          user.ballots[ballot.post_id] = ballot;
          // eslint-disable-next-line promise/catch-or-return
          services.db.save(userId, user).then(null).catch(null).finally(() => {
            resolve(services.buildResponse(response.data.message, null));
          });
        }).catch(error => {
          // reject with error
          reject(services.setError(error, services.service.VOTING));
        });
      });
    }).catch(error => {
      return Promise.reject(services.buildResponse(null, error));
    });
  };

  /**
	 * Checks if a user has voted already on a particular post. This
	 * information is kept and checked locally first and if it does not
	 * exist, it is requested to the voting server.
	 *
	 * @param {String} userId - EUNOMIA User ID
	 * @param {String} postId - ID of the post to be verified
   * @returns {Promise<{message:{voted: Boolean, ballot: Object}}>} Object
	 * stating whether a user has voted or not
	 */
  calls.hasVoted = function(userId, postId) {
    // define errors
    const errorInit = services.setError(`User ${userId} was not initialized properly`, services.service.CLIENT);

    // declare user variable
    let user;

    return services.db.get(userId).then(object => {
      if (object === null) {
        return Promise.reject(errorInit);
      }

      // check for post
      user = object;

      // return true if voted or server info
      return services.db.get(user.serverId);
    }).then(server => {
      if (server === null) {
        console.log(1);
        return Promise.reject(errorInit);
      }

      return server;
    }).then(() => {
      if (!user.ballots) {
        return Promise.resolve({ voted: false, ballot: null });
      }
      if (user.ballots && user.ballots[postId] !== undefined) {
        return Promise.resolve({ voted: true, ballot: user.ballots[postId] });
      }
      return Promise.resolve({ voted: false, ballot: null });
    }).then(state => {
      return Promise.resolve(services.buildResponse(state, null));
    }).catch(error => Promise.reject(services.buildResponse(null, error)));
  };

  /**
	 * Clears the local database instance or deletes the keys specified.
	 *
	 * @param {Array<String>} keys - Keys to be deleted from local storage or no arguments
	 * 							 to clear the whole database
	 * @return {Promise<Object>} Response object with the state of the operation
	 */
  calls.clear = function(keys=[]) {
    return services.db.clear(keys)
      .then(response => Promise.resolve(services.buildResponse(response, null)))
      .catch(error => Promise.reject(services.buildResponse(null, error)));
  };

  //------------------------------------------------------------------------------------------
  // AUXILIARY FUNCTIONS (NOT EXPORTED)
  //------------------------------------------------------------------------------------------

  /**
	 * This is auxiliary function that handles the eligibility process.
	 *
	 * @param {String} endpoint - Endpoint to request eligibility
	 * @param {Object} ballot - Ballot to be signed
	 *
	 * @return {Promise<Object>} Server response or Error
	 */
  function eligibility(endpoint, ballot) {
    // build ballot
    ballot.commitment = crypto.generateCommitment();

    return Promise.resolve({
      'ballot': ballot,
      'signatures': {
        '123456789': 'abcdefghijklmn',
      },
    });
  }

  calls.db = services.db;

  return calls;
})();

module.exports = library;
