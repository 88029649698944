const priorityGuidelines = [
  'When in doubt about a post, use the “i” button and check if “similar posts” have been posted before. Is the information recent or was it first posted years ago in different context?',
  'Check whether similar text or image has been shared before in different context. Use the “i” button and check if “similar posts” have been found by EUNOMIA.',
  'Most misinformation spreads between friends who share it unknowingly. If in doubt, don’t share.',
  'Never share an article based only on headline. That’s why misinformation spreads so much faster than reliable information.',
  'When coming across information that seems intriguing or unusual, click on “i” to see when the account was created. Did they join just to post this?',
  'Does a post over-encourage you to re-share? Misinformation aims to go viral.',
  'Is the language used in a post making you feel emotional? Misinformation aims to trigger emotional response to go viral.',
  'Be EXTRA careful with posts that make excessive use of exclamation points!!!!!',
  'If in doubt, do not share.',
  'Do you find something to be untrustworthy? Use the “I don’t trust this” button to help flag it for others.',
  'Do you immediately want to amplify the story by sharing it further? Stop and think.',
];
const standardGuidelines = [
  'If a post has an article linked, check the author. Be wary of anonymous articles.',
  'If a post has an article linked, check whether it is marked as sponsored content.',
  'If a post has an article linked, check whether any quoted experts do exist and are indeed relevant.',
  'If a post has an article linked, check whether the author provided any evidence.',
  'If a post has an article linked, be extra careful of news outlets you have never heard of before.',
  'Have you noticed an account posting at all times during the day and night? It might be a bot.',
  'If a post has an article linked, check whether the author uses what you would consider a reliable source.',
  'If a post’s information doesn’t sound believable, maybe it is meant to be a joke?',
  'If a post has a very one-sided article linked, consider looking for articles on the opposite side.',
  'Misinformation aims to go viral. Use the “i” button to check if “similar posts” on this topic have been found by EUNOMIA.',
  'Does the post or article linked argue that it is NOT false? Why would it if it is not?',
  'Is a piece of news too good to be true?',
  'When in doubt about an article linked by a post, compare its language to the language used by other sources.',
  'If in doubt about a piece of information, assess any potential impact, if proved to be false',
];
// const priorityGuidelines = [
//   'Most misinformation spreads between friends who share it unknowingly. If in doubt, don’t share.',
//   'Never share an article based only on headline. That’s why misinformation spreads so much faster than reliable information.',
//   'When coming across information that seems intriguing or unusual, click on “i” to see when the account was created. Did they join just to post this?',
//   'Does a post over-encourage you to re-share? Misinformation aims to go viral.',
//   'Is the language used in a post making you feel emotional? Misinformation aims to trigger emotional response to go viral.',
//   'Be EXTRA careful with posts that make excessive use of exclamation points!!!!!',
//   'If in doubt, do not share.',
//   'Do you find something to be untrustworthy? Use the “I don’t trust this” button to help flag it for others.',
//   'Do you immediately want to amplify the story by sharing it further? Stop and think.',
// ];
// const standardGuidelines = [
//   'If a post has an article linked, check the author. Be wary of anonymous articles.',
//   'If a post has an article linked, check whether it is marked as sponsored content.',
//   'If a post has an article linked, check whether any quoted experts do exist and are indeed relevant.',
//   'If a post has an article linked, check whether the author provided any evidence.',
//   'If a post has an article linked, be extra careful of news outlets you have never heard of before.',
//   'Have you noticed an account posting at all times during the day and night? It might be a bot.',
//   'If a post has an article linked, check whether the author uses what you would consider a reliable source.',
//   'If a post’s information doesn’t sound believable, maybe it is meant to be a joke?',
//   'If a post has a very one-sided article linked, consider looking for articles on the opposite side.',
//   'Does the post or article linked argue that it is NOT false? Why would it if it is not?',
//   'Is a piece of news too good to be true?',
//   'When in doubt about an article linked by a post, compare its language to the language used by other sources.',
//   'If in doubt about a piece of information, assess any potential impact, if proved to be false',
// ];

const getNextMotd = (daysDiff) => {
  // not randomly, sequence: three priority msgs, one standard
  const orders = [];
  let standardIndex = 0;
  let totalCounter = 0;
  for (let i = 0; i < (3 * standardGuidelines.length); i ++) {
    for (let j=0; j < priorityGuidelines.length; j ++) {
      orders.push(j);
      if (totalCounter % 3 === 2) {
        orders.push(priorityGuidelines.length + (standardIndex % standardGuidelines.length));
        standardIndex += 1;
      }
      totalCounter += 1;
    }
  }
  const _diff = daysDiff % totalCounter;
  if (_diff >= orders.length) {
    return priorityGuidelines[0];
  } else {
    const entryIndex = orders[_diff];
    if (entryIndex < priorityGuidelines.length) {
      return priorityGuidelines[entryIndex];
    } else {
      return standardGuidelines[entryIndex - priorityGuidelines.length];
    }
  }
};

export const getMotd = () => {
  const motdStarted = new Date('1970-01-01');
  const today = new Date();
  const utc1 = Date.UTC(motdStarted.getFullYear(), motdStarted.getMonth(), motdStarted.getDate());
  const utc2 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  const daysDiff =  Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
  return getNextMotd(daysDiff);
};
