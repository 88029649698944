import { EUNOMIA_CASCADE_IMPORT, EUNOMIA_CASCADE_OPEN, EUNOMIA_POST_IMPORT, EUNOMIA_POSTS_IMPORT } from '../constants';
import { getEunomiaHeaders } from './common';
import { myId } from '../../initial_state';
import axios from 'axios';
import { getUserPostVotes } from './voting';

export function importEunomiaPost(post) {
  return { type: EUNOMIA_POST_IMPORT, post };
}

export function importEunomiaPosts(posts) {
  return { type: EUNOMIA_POSTS_IMPORT, posts };
}

export function importEunomiaCascade(cascadeId, posts) {
  return { type: EUNOMIA_CASCADE_IMPORT, payload: { cascadeId, posts } };
}
export function setCascadeViewOpen(open) {
  return { type: EUNOMIA_CASCADE_OPEN, open };
}

export const onStatusAction = (status, action) => () => {
  try {
    let _status = status;
    if (status.get('reblog', null) !== null && typeof status.get('reblog') === 'object') {
      _status = status.get('reblog');
    }
    const sourceId = _status.get('id');
    const url = `/eunomia/api/mastodon/posts/${sourceId}`;
    getEunomiaHeaders().then((headers) => {
      if (action === 'delete') {
        axios.delete(url, { headers, timeout: 3000 }).then(()=>{}).catch(()=>{});
      } else {
        axios.patch(url, { action }, { headers, timeout: 3000 }).then(()=>{}).catch(()=>{});
      }
    }).catch(() => {});
  } catch {}
};

const getFetchingCascades = () => {
  const entriesKey = sessionStorage.getItem('FCs');
  if (entriesKey) {
    try {
      return JSON.parse(entriesKey);
    } catch (e) {
      return [];
    }
  }
  return [];
};

const setFetchingCascade = (cascadeId) => {
  let cascadesFetching = getFetchingCascades();
  if (!cascadesFetching) {
    cascadesFetching = [];
  }
  if (!cascadesFetching.includes(cascadeId)) {
    cascadesFetching.push(cascadeId);
  }
  window.sessionStorage.setItem('FCs', JSON.stringify(cascadesFetching));
};

const removeFetchingCascade = (cascadeId) => {
  let cascadesFetching = getFetchingCascades();
  cascadesFetching = cascadesFetching.filter(entry => entry !== cascadeId);
  window.sessionStorage.setItem('FCs', JSON.stringify(cascadesFetching));
};

const fetchCascade = (headers, cascadeId, dispatch, force=false) => {
  const cascadesFetching = getFetchingCascades();
  if (!cascadesFetching.includes(cascadeId) || force) {
    setFetchingCascade(cascadeId);
    const url = `/eunomia/api/cascades/${cascadeId}`;
    axios.get(url, { headers, timeout: 30000 }).then(({ data }) => {
      const postsToImport = [];
      data.forEach((post) => {
        getUserPostVotes(myId, post).then((_post) => {
          postsToImport.push(_post);
          if (postsToImport.length === data.length) {
            removeFetchingCascade(cascadeId);
            dispatch(importEunomiaCascade(cascadeId, postsToImport));
          }
        }).catch(() => {
          postsToImport.push(post);
          if (postsToImport.length === data.length) {
            removeFetchingCascade(cascadeId);
            dispatch(importEunomiaCascade(cascadeId, postsToImport));
          }
        });
      });
    }).catch((reason) => {
      // eslint-disable-next-line no-console
      console.log(reason);
      removeFetchingCascade(cascadeId);
    });
  }
};


export const onCascadeResults = (cascadeId) => (dispatch) => {
  getEunomiaHeaders().then((headers) => {
    fetchCascade(headers, cascadeId, dispatch, true);
  }).catch(() => {});
};


export const _getPostRequest = (url, status, headers, dispatch) => {
  axios.get(url, { headers, timeout: 30000 }).then(({ data }) => {
    if (data && data.source_id && data.source_url ) {
      getUserPostVotes(myId, data).then((post) => {
        post.original_post = status;
        dispatch(importEunomiaPost(post));
        if (post.cascade_id) {
          fetchCascade(headers, post.cascade_id, dispatch);
        }
      }).catch((reason) => {
        // eslint-disable-next-line no-console
        console.log(reason);
        data.voted = false;
        data.trusted = null;
        dispatch(importEunomiaPost(data));
      });
    }
  }).catch(() => {
  });
};

export const getEunomiaPost = (status) => (dispatch) => {
  try {
    let _status = status;
    if (status.get('reblog', null) !== null && typeof status.get('reblog') === 'object') {
      _status = status.get('reblog');
    }
    const sourceUrl = _status.get('url', null);
    if (sourceUrl) {
      const sourceId = _status.get('id');
      const jsStatus = _status.toJS();
      const url = `/eunomia/api/mastodon/posts/${sourceId}`;
      getEunomiaHeaders().then((headers) => {
        _getPostRequest(url, jsStatus, headers, dispatch);
      }).catch(() => {
        setTimeout(() => {
          getEunomiaHeaders().then((headers) => {
            _getPostRequest(url, jsStatus, headers, dispatch);
          }).catch(()=>{});
        }, 3000);
      });
    }
  } catch {}
};
