'use strict';

const forge = require('node-forge');
const secureRandom = require('secure-random');
// const randomString = require('crypto-random-string');
const randomString = ({ length }) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

let cryptography = (function() {

  // javascript big integer type (from node-forge)
  const BigInteger = forge.jsbn.BigInteger;

  // cryptographic options
  const AES_KEY_SIZE = 128;
  const AES_BLOCK_MODE = 'AES-CBC';
  const RSA_KEY_SIZE = 2048;
  const RSA_PADDING = 'RSAES-PKCS1-V1_5';

  // dictionary of methods exported by this module
  let calls = {};

  // split methods into symmetric and asymmetric
  let rsa = {};
  let aes = {};

  //------------------------------------------------------------------------------------------
  // KEYS & CERTIFICATES
  //------------------------------------------------------------------------------------------

  /**
	 * Generates an RSA 2048-bit key pair.
	 *
	 * @return {Object} RSA key pair (node-forge object)
	**/
  rsa.generateKeyPair = function() {
    // generate forge keys (much faster)
    let config = {
      'bits': RSA_KEY_SIZE,
      'e': 0x10001,
    };

    // generate and return keys
    return forge.pki.rsa.generateKeyPair(config);
  };

  /**
	 * Extracts the public key from an X.509 certificate.
	 *
	 * @param {String} certificate - X.509 certificate in PEM format
	 *
	 * @return {Object} Public Key (node-forge object)
	 **/
  rsa.keyFromCertificate = function(certificate) {
    // get certificate from pem
    let cert = forge.pki.certificateFromPem(certificate);

    // return key
    return cert.publicKey;
  };

  /**
	 * Generates a 128-bit key for AES.
	 *
	 * @return {String} AES key (base64)
	 */
  aes.generateKey = function() {
    // generate 16 random bytes
    let key = forge.random.getBytesSync(AES_KEY_SIZE / 8);

    // return key as base64
    return forge.util.encode64(key);
  };

  //------------------------------------------------------------------------------------------
  // HASH FUNCTIONS & RANDOM GENERATION
  //------------------------------------------------------------------------------------------

  /**
	 * Generates a random commitment for a specific ballot.
	 *
	 * @return {String} Random alphanumeric string of size 16
	**/
  calls.generateCommitment = function() {
    return randomString({ length: 16 });
  };

  /**
	 * Hashes received data using the predefined SHA-256 algorithm.
	 *
	 * @param {String/number/Object} data - Information to be hashed
	 *
	 * @return {String} Hashed data object (base64)
	**/
  calls.hash = function(data) {
    // create hash function object
    const hash = forge.md.sha256.create();
    let message = data;

    // convert object to json (if object)
    if (typeof data === 'object')
      message = JSON.stringify(data);

    // hash data
    let result = hash.update(message).digest();

    // return data in base64 format
    return forge.util.encode64(result.data);
  };

  //------------------------------------------------------------------------------------------
  // ENCRYPTION & DECRYPTION
  //------------------------------------------------------------------------------------------

  /**
	 * Encrypts data using AES-128-CBC. For security reasons, the initialization
	 * vector (IV) is generated locally so that it cannot be reused.
	 *
	 * @param {String/Object} data - Can be anything, will be converted to JSON first
	 * @param {String} key - Key used to encrypt (base64)
	 *
	 * @return {{data: *, iv: String}} Encrypted data and initialization
	 * vector (both in base64)
	 **/
  aes.encrypt = function(data, key) {
    // key from base64 to bytes
    key = forge.util.decode64(key);

    // generate init vector
    let iv = forge.random.getBytesSync(16);

    // create cipher
    const cipher = forge.cipher.createCipher(AES_BLOCK_MODE, key);
    cipher.start({ iv: iv });

    // prepare data (convert
    data = JSON.stringify(data);

    // encrypt
    cipher.update(forge.util.createBuffer(data));
    let result = cipher.finish();

    // convert to base64
    let encrypted = (result) ? forge.util.encode64(cipher.output.data) : null;
    iv = forge.util.encode64(iv);

    // return data and iv
    return {
      'data': encrypted,
      'iv': iv,
    };
  };

  /**
	 * Decrypts data encrypted with AES-128-CBC.
	 *
	 * @param {String} data - Encrypted data (base64)
	 * @param {String} key - Key used to decrypt (base64)
	 * @param {String} iv - Initialization vector used during encryption (base64)
	 *
	 * @return {Object/String} Decrypted object
	 **/
  aes.decrypt = function(data, key, iv) {
    // convert parameters from base64 to bytes
    data = forge.util.decode64(data);
    key = forge.util.decode64(key);
    iv = forge.util.decode64(iv);

    // create and start decipher
    const decipher = forge.cipher.createDecipher(AES_BLOCK_MODE, key);
    decipher.start({ iv: iv });

    // decrypt
    decipher.update(forge.util.createBuffer(data));
    let result = decipher.finish();

    // prepare output
    let decrypted = null;
    if (result) {
      try {
        decrypted = JSON.parse(decipher.output.data);
      } catch (_){}
    }

    // return decrypted data
    return decrypted;
  };

  /**
	 * Encrypts data using RSA (PKCS1 Padding).
	 *
	 * @param {String/Object} data - Can be anything, will be converted to JSON first
	 * 								 if necessary
	 * @param {Object} publicKey - Public key to encrypt (node-forge object)
	 *
	 * @return {String} Encrypted object (base64)
	 **/
  rsa.encrypt = function(data, publicKey) {
    // convert to string
    data = JSON.stringify(data);

    // encrypt (returns bytes)
    let encryption = publicKey.encrypt(data, RSA_PADDING);

    // convert to base64
    return forge.util.encode64(encryption);
  };

  /**
	 * Decrypts data encrypted with RSA (PKCS1 Padding).
	 *
	 * @param {String} data - Encrypted data (base64)
	 * @param {Object} privateKey - Private key to decrypt (node-forge object)
	 *
	 * @return {Object/String} Decrypted object
	**/
  rsa.decrypt = function(data, privateKey) {
    // base64 to bytes
    let bytes = forge.util.decode64(data);

    // decrypt (returns bytes)
    let decryption = privateKey.decrypt(bytes, RSA_PADDING);

    // handle return data
    try {
      decryption = JSON.parse(decryption);
    } catch (_){}

    return decryption;
  };

  //------------------------------------------------------------------------------------------
  // SIGNATURES
  //------------------------------------------------------------------------------------------

  /**
	 * Signs data using RSA (PKCS1) and SHA256.
	 *
	 * @param {Object} data - Can be anything, will be converted to JSON first
	 * 						  (if object) and then hashed into SHA256
	 * @param {Object} privateKey - Private key to sign (node-forge object)
	 *
	 * @return {String} Signed object (base64)
	**/
  rsa.sign = function(data, privateKey) {
    // convert to json if necessary
    if (typeof data === 'object')
      data = JSON.stringify(data);

    // convert data to hashed bytes
    let bytes = forge.md.sha256.create().update(data);

    // sign data (in bytes)
    let signature = privateKey.sign(bytes);

    // encode to base64 and return
    return forge.util.encode64(signature);
  };

  /**
	 * Verifies data signed with RSA (PKCS1) and SHA256.
	 *
	 * @param {Object} data - Information that was signed
	 * @param {String} signature - Result of the sign function (base64)
	 * @param {Object} publicKey - Public key to verify signature (node-forge object)
	 *
	 * @return {boolean} True if signature verifies, false otherwise
	**/
  rsa.verify = function(data, signature, publicKey) {
    // convert signature to bytes
    let bytes = forge.util.decode64(signature);

    // prepare data
    let digest = forge.util.decode64(calls.hash(data));

    // return boolean to verify signature
    return publicKey.verify(digest, bytes);
  };

  //------------------------------------------------------------------------------------------
  // BLIND/UNBLIND DATA
  //------------------------------------------------------------------------------------------

  /**
	 * Blinds information to be later signed.
	 *
	 * @param {String} data - Data to be blinded before signature
	 * @param {Object} publicKey - Public key (node-forge object)
	 *
	 * @return {{data: String, factor: BigInteger}} Data in base64 encoding
	**/
  rsa.blind = function(data, publicKey) {
    // hash data
    let hash = calls.hash(data);

    // TODO: pad hash (pkcs#1)
    let paddedHash = hash;

    // convert to int
    let messageInt = new BigInteger('0x' + Buffer.from(paddedHash, 'base64').toString('hex'), 16);

    // get public key data (modulus, exponent)
    let modulus = publicKey.n;
    let exponent = publicKey.e;

    // create values
    const one = BigInteger.ONE;
    let gcd = new BigInteger('0');
    let r = new BigInteger('0');

    // generate random component
    while (!gcd.equals(one) || r.compareTo(modulus) >= 0 || r.compareTo(one) <= 0) {
      r = new BigInteger(secureRandom(64)).mod(modulus);
      gcd = r.gcd(modulus);
    }

    // calculate blinding factor
    let factor = r.modPow(exponent, modulus);

    // blind message (int format)
    let result = messageInt.multiply(factor).mod(modulus);

    // encode blinded message to base64
    let blinded = forge.util.encode64(result.toString());

    // return dictionary
    return {
      'data': blinded,
      'factor': r,
    };
  };

  /**
	 * Unblinds information signed using RSA.
	 *
	 * @param {String} signature - Data to be unblinded after blind signature (base64)
	 * @param {BigInteger} factor - Component **r** of the blinding factor used to hide information
	 * @param {Object} publicKey - Public key (node-forge object)
	 *
	 * @return {String} Unblinded data (base64)
	**/
  rsa.unblind = function(signature, factor, publicKey) {
    // convert base64 to big integer
    let signed = new BigInteger('0x' + Buffer.from(signature, 'base64').toString('hex'), 16);

    // public key modulus
    let modulus = publicKey.n;

    // unblind data
    const unblinded = signed.multiply(factor.modInverse(modulus)).mod(modulus);

    // encode to base64 and return
    // data is now signed and able to verified normally
    return forge.util.encode64(unblinded.toString());
  };

  // add methods to be exported
  calls.rsa = rsa;
  calls.aes = aes;

  return calls;
})();

module.exports = cryptography;
