import { Map as ImmutableMap, fromJS } from 'immutable';
import {
  EUNOMIA_CASCADE_IMPORT,
} from '../constants';

const initialState = ImmutableMap();

const importCascade = (state, payload) => {
  const { cascadeId, posts } = payload;
  if (cascadeId && posts) {
    // console.log(posts.length);
    return state.set(cascadeId, fromJS(posts));
  }
  return state;
};

export default function eunomiaCascades(state = initialState, action) {
  if (action.type === EUNOMIA_CASCADE_IMPORT){
    return importCascade(state, action.payload);
  }
  return state;
}
