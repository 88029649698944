import Rails from '@rails/ujs';
import votingOld from '../eunomia/voting_old/lib';
import votingNew from '../eunomia/voting_new/lib';
import { NEW_VOTING } from '../eunomia/constants';

const voting = NEW_VOTING ? votingNew : votingOld;

export const logOut = () => {
  // eslint-disable-next-line no-console,promise/catch-or-return
  voting.clear([]).then().catch((err) => console.warn(err)).finally(() => {
    const form = document.createElement('form');

    const methodInput = document.createElement('input');
    methodInput.setAttribute('name', '_method');
    methodInput.setAttribute('value', 'delete');
    methodInput.setAttribute('type', 'hidden');
    form.appendChild(methodInput);

    const csrfToken = Rails.csrfToken();
    const csrfParam = Rails.csrfParam();

    if (csrfParam && csrfToken) {
      const csrfInput = document.createElement('input');
      csrfInput.setAttribute('name', csrfParam);
      csrfInput.setAttribute('value', csrfToken);
      csrfInput.setAttribute('type', 'hidden');
      form.appendChild(csrfInput);
    }

    const submitButton = document.createElement('input');
    submitButton.setAttribute('type', 'submit');
    form.appendChild(submitButton);

    form.method = 'post';
    form.action = '/auth/sign_out';
    form.style.display = 'none';

    document.body.appendChild(form);
    submitButton.click();
  });
};
