/* eslint-disable no-console, consistent-return, no-unused-vars */
'use strict';

const axios = require('axios');
const crypto = require('./cryptography.js');
const services = require('./services.js');

// performance
//const process = require('process');
const sizeof = require('object-sizeof');

let library = (function() {

  // paths for the API calls made by this library
  const BALLOT_PATH = '/ballot';
  const FEATURES_PATH = '/features';
  const ELIGIBILITY_PATH = '/eligibility';
  const TALLY_PATH = '/tally';
  const TALLIES_PATH = '/tallies';
  const PUBLIC_VOTE_PATH = '/vote';
  const DELETE_VOTE_PATH = '/unvote';
  const ANONYMOUS_VOTE_PATH = '/anonymize';

  // default mix-net size (including first node)
  const MIX_SIZE = 3;

  // size of zkp for voting (number of public keys)
  const PROOF_SIZE = 5;

  // dictionary of methods exported by this module
  let calls = {};

  //------------------------------------------------------------------------------------------
  // MAIN FUNCTIONS (EXPORTED)
  //------------------------------------------------------------------------------------------

  /**
	 * This call must be made whenever there is a new user logging in or signing up.
	 * New information will be saved locally and used later.
	 * Note that if the voting server changes its credentials (but keeps identifier and endpoint),
	 * all subsequent signature verifications will fail since the initialization process uses
	 * previously saved credentials. To avoid this, delete the voting server identifier from
	 * the local storage and only then call the initialization process again.
	 * The initialization process encompasses all of these procedures (chained promises):
	 *
	 * 1. Get Node Information (including Voting Server ID)
	 * 2. Set Discovery Service URL (same as ESN URL)
	 * 3. Look for Voting Server credentials saved locally
	 * 4. If these do not exist, query Discovery Service to get Voting Server credentials
	 * 5. Look for the user's credentials saved locally
	 * 6. If these do not exist, call Voting Server to do one of the following:
	 * 		6.1 Register a new voter
	 * 		6.2 Login the voter
	 * 7. Save the association between voter and node as well as any other important information
	 *	  regarding these two entities (such as endpoint and keys)
	 *
	 * @param {String} nodeEndpoint - Endpoint (with base url and port) of the EUNOMIA Services Node
	 * where the discovery and storage services are running (e.g. http://146.193.69.131:5000/)
	 * @param {String} votingEndpoint - Endpoint (with base url and port) of the Voting Server
	 * (e.g. http://146.193.69.131:8100/)
	 * @param {String} accessToken - EUNOMIA Access Token for the user, retrieved through EUNOMIA's infrastructure
	 * @param {String} userId - Social network user identifier
	 * @param {Boolean} register - Specifies whether the voter is being registered or logged in (true for registration)
	 * @param {String} secret - Secret related to the user such as a password or the hashed password, which
	 * will be used to generate a key to encrypt and save the private key remotely
	 * @returns {Promise<Object>} State of the initialization process or Error
	 */
  calls.initialize = function(nodeEndpoint, votingEndpoint, accessToken, userId, register, secret) {
    // promise chain
    return services.node.getInfo(nodeEndpoint).then(node => {
      // configure node
      return services.node.configure(nodeEndpoint)
        .then(_ => services.db.get(node.votingServiceId))
        .then(server => {
          // if node is not found, search services for the
          // node id and save it locally
          if (server === null || server.endpoint !== votingEndpoint)
            return services.discovery.getVotingServer(votingEndpoint, accessToken, node.votingServiceId);

          // return local server info
          return server;
        });
    }).then(server => {
      return services.db.get(userId).then(user => {
        return { 'server': server, 'user': user };
      });
    }).then(data => {
      // user not saved locally
      if (data.user === null)
        if (register)
          return services.users.register(data.server.id, accessToken, userId, secret);
        else
          return services.users.login(data.server.id, accessToken, userId, secret);

      // resolve user handling
      return data.user;
    }).then(_ => services.response.build(`Initialization of user ${userId} was successful`, null),
    ).catch(error => Promise.reject(services.response.build(null, error)));
  };

  /**
	 * The DC UI calls this method to receive a ballot and the features that can be appended to it.
	 * The library NEEDS to be initialized for this call to work.
	 * The chained promises of these function are executed in the following order:
	 *
	 * 1. Check if voter exists
	 * 2. Check if the Voting Server for that voter also exists
	 * 3. Call the Voting Server to request a new ballot and user features
	 *
	 * @param {String} accessToken - EUNOMIA Access Token for the user, retrieved by EUNOMIA's infrastructure
	 * @param {String} postId - ID of the post that matches the ballot being returned
	 * @param {String} userId - SN identifier of the user to request the features for
	 * @returns {Promise<Object>} JSON with ballot and features or Error
	**/
  calls.getBallot = function(accessToken, postId, userId) {
    // declare initialization error
    const errorInit = services.response.setError(`User ${userId} was not initialized properly`, services.service.CLIENT);
    // chain promises
    return services.db.get(userId).then(user => {
      if (user === null)
        return Promise.reject(errorInit);

      return services.db.get(user.serverId);
    }).then(server => {
      if (server === null)
        return Promise.reject(errorInit);

      return server;
    }).then(server => {
      // build urls
      let ballotUrl = `${server.endpoint + BALLOT_PATH}?post_id=${postId}`;
      let featuresUrl = `${server.endpoint + FEATURES_PATH}?access_token=${accessToken}&user_id=${userId}`;

      // build both requests
      let request = [axios.get(ballotUrl), axios.get(featuresUrl)];

      // make request asynchronously
      return new Promise((resolve, reject) => {
        axios.all(request).then(responses => {
          // verify signatures for both requests
          responses.forEach(response => {
            if (!services.response.verify(server.manager, response))
              return reject(services.response.setError('Manager signature is invalid',
                services.service.VOTING));
          });

          // build library response
          let result = {
            'ballot': responses[0].data.data,
            'features': responses[1].data.data,
          };

          // return ballot + features
          resolve(services.response.build(result, null));
        }).catch(error => {
          reject(services.response.setError(error, services.service.VOTING));
        });
      });
    }).catch(error => Promise.reject(services.response.build(null, error)));
  };

  /**
	 * The DC UI calls this function to vote. This call is responsible for handling all processes
	 * related to the voting procedure, which may include generation of values, application
	 * of cryptographic functions and more.
	 * The library NEEDS to be initialized for this call to work.
	 * The chain of promises flows as follows:
	 *
	 * 1. Check if voter exists
	 * 2. Check if the Voting Server for that voter also exists
	 * 3. Reset Discovery Service URL to point to user's node
	 * 4. Compute vote proof
	 * 5. Encrypt the data being sent (ballot and features) through the mix network (if voting anonymously)
	 * 6. Call the Voting Server to handle the voting (and anonymization) process
	 *
	 * @param {String} accessToken - EUNOMIA Access Token for the user, retrieved through EUNOMIA's infrastructure
	 * @param {String} userId - SN identifier of the user who is voting
	 * @param {Object} body - Body of the vote request which contains a ballot (key: ballot) and optionally features
	 * (key: features); the ballot is an object represented as dictionary {post_id: <string>, votes: <object>} and
	 * the features the voter would like to append to the ballot are also are dictionary {"user_id": <string>,
	 * "features": <object>}; both of these are equal to the object returned by the getBallot function but updated
	 * according to user preferences
	 * @param {Boolean} anonymous - Specifies if the vote operation is to be carried out publicly or anonymously
	 * @returns {Promise<Object>} Server response or Error
	**/
  calls.vote = function(accessToken, userId, body, anonymous) {
    // count time
    //let start = process.hrtime();

    // define errors
    const errorInit = services.response.setError(`User ${userId} was not initialized properly`, services.service.CLIENT);
    const errorFormat = services.response.setError('Voting body has invalid format', services.service.CLIENT);
    // verify body format
    if (typeof body !== 'object' || !('ballot' in body))
      return Promise.reject(services.response.build(null, errorFormat));

    // verify ballot
    let ballot = body.ballot;
    if (typeof ballot !== 'object' || !('post_id' in ballot) || !('votes' in ballot))
      return Promise.reject(services.response.build(null, errorFormat));

    // verify features
    let features = null;
    if ('features' in body) {
      features = body.features;
      if (typeof features !== 'object' || !('user_id' in features) || !('features' in features))
        return Promise.reject(services.response.build(null, errorFormat));
    }

    // helper variables
    let user, server;

    // chain promises
    return services.db.get(userId).then(object => {
      if (object === null)
        return Promise.reject(errorInit);

      // set user variable and get server
      user = object;
      return services.db.get(user.serverId);
    }).then(object => {
      if (object === null)
        return Promise.reject(errorInit);

      // set server variable and configure
      server = object;
      return services.node.configure(server.node);
    }).then(_ => {

      // get voters' public keys
      return services.users.getKeys(accessToken, PROOF_SIZE - 1, user.pk).then(keys => {
        console.log('vote() ' + ' with keys:'+JSON.stringify(keys));
        // compute vote proof and append to ballot
        ballot.proof = crypto.ecc.computeProof(keys, { 'pk': user.pk, 'sk': user.sk }, ballot);
        // encrypt ballot if voting anonymously
        if (anonymous) return services.anonymize(accessToken, MIX_SIZE, user.serverId, ballot);
        // return ballot if not voting anonymously
        return ballot;
      });
    }).then(body => {
      // build voting url
      let url = server.endpoint + (anonymous ? `${ANONYMOUS_VOTE_PATH}?access_token=${accessToken}` : PUBLIC_VOTE_PATH);
      // make vote request asynchronously
      return new Promise((resolve, reject) => {
        axios.post(url, body, {}).then(response => {
          // verify signature
          if (!services.response.verify(server.tallier, response))
            return reject(services.response.setError('Tallier signature is invalid',
              services.service.VOTING));

          // measure time
          //let end = process.hrtime(start);
          //console.log(`Took ${end[0]}s and ${end[1] / 1000000}ms to vote on post ${ballot.post_id}`);

          // resolve promise
          resolve(services.response.build(response.data.message, null));

          // update voted posts and save
          delete ballot.proof;
          if (!user.ballots) {
            user.ballots = {};
          }
          user.ballots[ballot.post_id] = ballot;
          services.db.save(userId, user);
        }).catch(error => {
          // reject with error
          reject(services.response.setError(error, services.service.VOTING));
        });
      });
    }).catch(error => Promise.reject(services.response.build(null, error)));
  };

  /**
	 * Checks if a user has voted already on a particular post. This
	 * information is kept and checked locally first and if it does not
	 * exist, it is requested to the voting server. The library NEEDS to be
	 * initialized for this call to work.
	 *
	 * @param {String} userId - Social network user identifier
	 * @param {String} postId - ID of the post to be verified
	 * @returns {Promise<{voted: Boolean, ballot: Object}>} Object
	 * stating whether a user has voted or not (true/false) and a ballot
	 * if the voter has voted before
	 */
  calls.hasVoted = function(userId, postId) {
    // define errors
    const errorInit = services.response.setError(`User ${userId} was not initialized properly`, services.service.CLIENT);

    // chain promises
    return services.db.get(userId).then(user => {
      if (user === null || user.ballots === undefined)
        return Promise.reject(errorInit);

      // return server info to make request
      return services.db.get(user.serverId).then(server => {
        if (server === null)
          return Promise.reject(errorInit);

        return { 'user': user, 'server': server };
      });
    }).then(res => {
      // return ballot immediately if has voted
      if (res.user.ballots && res.user.ballots[postId] !== undefined)
        return Promise.resolve(services.response.build({ 'voted': true, 'ballot': res.user.ballots[postId] }, null));

      // compute nym
      let pseudonym = crypto.ecc.computeNym(res.user.sk, postId);
      let nym = crypto.ecc.compress(pseudonym.nym);

      // build url
      let url = res.server.endpoint + ELIGIBILITY_PATH;

      // check eligibility through voting server
      return new Promise((resolve, reject) => {
        axios.post(url, { 'nym': nym }, {}).then(response => {
          // verify signature
          if (!services.response.verify(res.server.manager, response))
            return reject(services.response.setError('Manager signature is invalid',
              services.service.VOTING));

          // return server response
          resolve(services.response.build(response.data.data, null));

          // update user info locally (if appropriate)
          if (response.data.data.voted === true) {
            res.user.ballots[postId] = response.data.data.ballot;
            services.db.save(userId, res.user);
          }
        }).catch(error => {
          reject(services.response.setError(error, services.service.VOTING));
        });
      });
    }).catch(error => Promise.reject(services.response.build(null, error)));
  };

  /**
	 * This call is made whenever a vote needs to be deleted. It computes the
	 * pseudonym for the voter and tells the voting server to delete the ballot.
	 * The library NEEDS to be initialized for this call to work.
	 *
	 * @param {String} userId - Social network user identifier
	 * @param {String} postId - ID of the post to be verified
	 * @returns {Promise<{deleted: Boolean}>} Object stating whether the ballot was
	 * successfully deleted or not
	 */
  calls.deleteVote = function(userId, postId) {
    // define errors
    const errorInit = services.response.setError(`User ${userId} was not initialized properly`, services.service.CLIENT);

    // chain promises
    return services.db.get(userId).then(user => {
      if (user === null || user.ballots === undefined)
        return Promise.reject(errorInit);

      // return server info to make request
      return services.db.get(user.serverId).then(server => {
        if (server === null)
          return Promise.reject(errorInit);

        return { 'user': user, 'server': server };
      });
    }).then(res => {
      // compute nym
      let pseudonym = crypto.ecc.computeNym(res.user.sk, postId);
      let nym = crypto.ecc.compress(pseudonym.nym);

      // build url
      let url = res.server.endpoint + DELETE_VOTE_PATH;

      // check eligibility through voting server
      return new Promise((resolve, reject) => {
        axios.post(url, { 'nym': nym }, {}).then(response => {
          // verify signature
          if (!services.response.verify(res.server.manager, response))
            return reject(services.response.setError('Manager signature is invalid',
              services.service.VOTING));

          // update locally
          delete res.user.ballots[postId];
          services.db.save(userId, res.user);

          // return server response
          resolve(services.response.build(response.data.data, null));
        }).catch(error => {
          reject(services.response.setError(error, services.service.VOTING));
        });
      });
    }).catch(error => Promise.reject(services.response.build(null, error)));
  };

  /**
	 * The DC UI calls this method whenever it wishes to present a user
	 * with the voting results of a post. Votes are returned separately
	 * and not merged together so that the UI can handle and display them
	 * as desired. The library DOES NOT need to be initialized for this
	 * call to work.
	 *
	 * @param {String} nodeEndpoint - Endpoint (with base url and port) of the
	 * EUNOMIA Services Node where the discovery and storage services are running
	 * (e.g. http://146.193.69.131:5000/)
	 * @param {String} votingEndpoint - Endpoint (with base url and port) of the
	 * Voting Server (e.g. http://146.193.69.131:8100/)
	 * @param {String} accessToken - Might be needed to retrieve the tallier's
	 * credentials in order to verify the tally signature, otherwise unnecessary
	 * @param {String} postId - Identifier of the post
	 * @returns {Promise<Object[]>} Array that contains objects that represent the
	 * votes (as shown by the voting server API)
	 */
  calls.tally = function(nodeEndpoint, votingEndpoint, accessToken, postId) {
    // promise chain
    console.log('tally(): nodeEndpoint: '+nodeEndpoint + ' votingEndpoint:'+votingEndpoint);
    return services.node.getInfo(nodeEndpoint).then(node => {
      // configure node
      return services.node.configure(nodeEndpoint)
        .then(_ => services.db.get(node.votingServiceId))
        .then(server => {
          // if node is not found, search services for the
          // node id and save it locally
          if (server === null || server.endpoint !== votingEndpoint)
            return services.discovery.getVotingServer(votingEndpoint, accessToken, node.votingServiceId);

          // return local server info
          return server;
        });
    }).then(server => {
      // build url
      let url = server.endpoint + `${TALLY_PATH}?post_id=${postId}`;

      // make tally request asynchronously
      return new Promise((resolve, reject) => {
        axios.get(url).then(response => {
          // verify signature
          if (!services.response.verify(server.tallier, response))
            return reject(services.response.setError('Tallier signature is invalid',
              services.service.VOTING));

          // return tally
          resolve(services.response.build(response.data.data, null));
        }).catch(error => {
          // reject with error
          reject(services.response.setError(error, services.service.VOTING));
        });
      });
    }).catch(error => Promise.reject(services.response.build(null, error)));
  };

  /**
	 * The DC UI calls this method whenever it wishes to present a user
	 * with the voting results of a post. Votes are returned separately
	 * and not merged together so that the UI can handle and display them
	 * as desired. The library DOES NOT need to be initialized for this
	 * call to work.
	 *
	 * @param {String} nodeEndpoint - Endpoint (with base url and port) of the
	 * EUNOMIA Services Node where the discovery and storage services are running
	 * (e.g. http://146.193.69.131:5000/)
	 * @param {String} votingEndpoint - Endpoint (with base url and port) of the
	 * Voting Server (e.g. http://146.193.69.131:8100/)
	 * @param {String} accessToken - Might be needed to retrieve the tallier's
	 * credentials in order to verify the tally signature, otherwise unnecessary
	 * @param {Array<String>} postIds - List of post identifiers
	 * @returns {Promise<Object[]>} Array that contains objects that represent the
	 * votes (as shown by the voting server API)
	 */
  calls.tallies = function (nodeEndpoint, votingEndpoint, accessToken, postIds) {
    return services.node.getInfo(nodeEndpoint).then(node => {
      // configure node
      return services.node.configure(nodeEndpoint)
        .then(_ => services.db.get(node.votingServiceId))
        .then(server => {
          // if node is not found, search services for the
          // node id and save it locally
          if (server === null || server.endpoint !== votingEndpoint)
            return services.discovery.getVotingServer(votingEndpoint, accessToken, node.votingServiceId);

          // return local server info
          return server;
        });
    }).then(server => {
      // build url
      let url = server.endpoint + `${TALLIES_PATH}?${postIds.map(x => 'post_id=' + x).join('&')}`;

      // make tally request asynchronously
      return new Promise((resolve, reject) => {
        axios.get(url).then(response => {
          // verify signature
          if (!services.response.verify(server.tallier, response))
            return reject(services.response.setError('Tallier signature is invalid',
              services.service.VOTING));

          // return tally
          resolve(services.response.build(response.data.data, null));
        }).catch(error => {
          // reject with error
          reject(services.response.setError(error, services.service.VOTING));
        });
      });
    }).catch(error => Promise.reject(services.response.build(null, error)));
  };

  /**
	 * Clears the local database instance or deletes the keys specified.
	 * This method MUST BE CALLED whenever the user logs out otherwise
	 * user sensitive information will be kept. The library DOES NOT need
	 * to be initialized for this call to work.
	 *
	 * @param {Array<String>} keys - Keys to be deleted from local storage or no
	 * arguments to clear the whole database (to delete a user, add the user id
	 * as an argument)
	 * @returns {Promise<Object>} Response object with the state of the operation
	 */
  calls.clear = function(keys = []) {
    return services.db.clear(keys)
      .then(response => Promise.resolve(services.response.build(response, null)))
      .catch(error => Promise.reject(services.response.build(null, error)));
  };

  calls.db = services.db;

  return calls;
})();

module.exports = library;
